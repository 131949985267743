/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const Announcement: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="announcement" ref={ref}  {...props}>
            <path
                d={isLarge ? "M159.9998 280.002H40C28.9542 280.002 20 271.0476 20 260.002V160.002C20 148.956 28.9544 140.002 40 140.002C53.3334 140.002 66.6668 140.002 80.0002 140.002V40.002C80.0002 28.956 88.9544 20.002 100 20.002C111.0458 20.002 120 28.956 120 40.002V140.002H159.9998L265.858 34.144C278.4580000000001 21.544 300 30.468 300 48.286V210.002V371.7178C300 389.5358 278.4580000000001 398.45915 265.858 385.8598600000001L159.9998 280.002zM330.732 124.952C361.366 141.078 380 169.246 380 200.0018C380 230.5428 361.216 258.6634 330.7800000000001 274.9408C325.766 277.6216 320 273.7768 320 268.092V199.958V131.822C320 126.162 325.7240000000001 122.316 330.732 124.952z" : "M140 220.0058C132.7406 220.3404 56.8058 220.1702 19.90814 220.0664C8.88906 220.0354 0 211.0928 0 200.0738V140.0058C0 128.9602 8.9543 120.0058 20 120.0058H60V18.864C60 8.274 68.2668 -0.066 78.8562 -0.05C79.4038 -0.05 79.9526 -0.048 80.501 -0.048C91.5514 -0.03 100.4746 8.968 100.4274 20.018L100 120.0058H140L204.556 41.546C216.48 27.052 240 35.484 240 54.252V284.7648C240 303.47078 216.61 311.9388 204.636 297.5684L140 220.0058zM300 160.0058C300 139.6314 287.684 119.074 270.858 106.594C266.124 103.084 260 106.834 260 112.726V204.6106C260 210.5302 266.1840000000001 214.305 270.994 210.8556C287.678 198.8928 300 180.1854 300 160.0058z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
Announcement.defaultProps = {
    size: IconSize.STANDARD,
};
Announcement.displayName = `Blueprint5.Icon.Announcement`;
export default Announcement;
