import './index.scss'

import { useState } from 'react'
import Lang from '../../Lang'
import Page from '../../Components/Page'
import { TimezoneSelect } from '@blueprintjs/datetime2'
import { Button, InputGroup, FormGroup } from '@blueprintjs/core'
import { useAppContext } from '../../Components/App'

export default function Settings() {
	const { account } = useAppContext()

	const [formData, setFormData] = useState({
		id: account.id,
		name: account.name,
		timeZone: account.timeZone,
		busy: false
	})

	const save = async () => {
		setFormData((prev) => ({ ...prev, busy: true }))
		await API.call('Account.Edit', {
			id: formData.id,
			name: formData.name,
			timeZone: formData.timeZone
		})
		location.reload()
	}

	return (
		<Page title={Lang.get('Settings')} icon="fa-duotone fa-gear" className="SettingsPage">
			<FormGroup label={Lang.get('Account Name')}>
				<InputGroup value={formData.name} onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} />
			</FormGroup>
			<FormGroup label={Lang.get('Time Zone')}>
				<TimezoneSelect
					showLocalTimezone
					value={formData.timeZone}
					onChange={(tz) => setFormData((prev) => ({ ...prev, timeZone: tz }))}
				/>
			</FormGroup>
			<Button intent="primary" disabled={formData.busy} icon="tick" text={Lang.get('Save')} onClick={save} />
		</Page>
	)
}
