import './IconTextBox.scss'

export default function IconTextBox({ children, iconElement, className, contentStyle }) {
	return (
		<div className={`icon-text-box ${className}`} style={contentStyle}>
			<div className="icon-wrapper">{iconElement}</div>

			<span className={`content`}>{children}</span>
		</div>
	)
}
