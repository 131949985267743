import './DeviceCommandViewer.scss'

import React from 'react'
import API from '../../../../../../../API'
import { DateTime } from 'luxon'
import { Button } from '@blueprintjs/core'

export default function DeviceCommandViewer(props) {
	return (
		<div className="DeviceCommandViewer">
			{props.commands?.map((c, i) => (
				<DeviceCommand key={`dc-${i}${props.device.id}`} command={c} device={props.device} />
			))}
		</div>
	)
}

class DeviceCommand extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			command: props.command,
			busy: false
		}
	}

	async step(forward = false) {
		if (this.state.busy) {
			return
		}
		this.setState({ busy: true })
		const c = this.state.command
		console.log('step', c)
		const q = {
			deviceId: this.props.device.id,
			commandType: c.commandType,
			limit: 1
		}
		if (forward) {
			q.afterId = c.id
		} else {
			q.beforeId = c.id
		}
		const data = await API.call('Admin.RF.Query', q)
		if (data && data.length > 0) {
			this.setState({ command: data[0] })
		}
		this.setState({ busy: false })
	}

	render() {
		const c = this.state.command
		const time = DateTime.fromMillis(c.time).toFormat('yyyy-MM-dd HH:mm:ss')
		const payloadHex = c.payloadHex || ''
		const rawHex = payloadHex.match(/.{1,2}/g)?.join(' ')
		return (
			<div className="DeviceCommand">
				<div className="command">
					<div className="section name">
						<div className="content">
							{c.commandName} (#{c.commandType})
						</div>
					</div>
					<div className="section received">
						<div className="title">Received</div>
						<div className="switcher">
							<Button size="small" icon="chevron-left" onClick={() => this.step(true)} disabled={this.state.busy} />
							<div className="content">{time}</div>
							<Button size="small" icon="chevron-right" onClick={() => this.step(false)} disabled={this.state.busy} />
						</div>
					</div>
					<div className="oneRow">
						<div className="section gatewayId">
							<div className="title">Gateway ID</div>
							<div className="content">{c.gatewayId}</div>
						</div>
						<div className="section rssi">
							<div className="title">RSSI</div>
							<div className="content">{c.rssi}</div>
						</div>
					</div>
					<div className="section hex">
						<div className="title">RAW HEX ({payloadHex.length / 2} bytes)</div>
						<div className="content">{rawHex}</div>
					</div>
					<div className="section decoded">
						<div className="title">Decoded</div>
						<div className="content">
							{c.commandName == 'DEBUG_MSG'
								? Buffer.from(payloadHex, 'hex').toString('utf8')
								: JSON.stringify(c.payload, null, 2)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
