import { useState } from 'react'

export default function useSettings(defaultSettings) {
	const [settings, _setSettings] = useState(defaultSettings)

	// key (string): mapType, icons.color, icons.view.idLabel
	function setSetting(key, value) {
		const path = key.split('.')

		let currentObject = settings

		// Iterate through the path, updating nested objects
		path.forEach((segment, index) => {
			if (index === path.length - 1) {
				// Last segment: set the value
				currentObject[segment] = value
			} else {
				// Intermediate segment: ensure it exists and is an object
				if (!currentObject[segment] || typeof currentObject[segment] !== 'object') {
					throw new Error(`useSettings invalid path: ${key}`)
				}
				currentObject = currentObject[segment]
			}
		})

		// Update the state with the modified object
		_setSettings({ ...settings })
	}

	return { setSetting, settings }
}
