import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import './ChartGroup.scss'
import { DateTime } from 'luxon'
import { Button } from '@blueprintjs/core'

const ChartGroupContext = createContext(null)

export default function ChartGroup({ children, dateValue, onDateChange }) {
	function adjustDays(num) {
		onDateChange(dateValue.plus({ days: num }))
	}

	return (
		<div className="chartGroup">
			<ChartGroupContext.Provider value={{ curDate: dateValue }}>
				<div className="dateChanger container-shadow">
					<Button size="small" icon="chevron-left" onClick={() => adjustDays(-1)} />
					<div className="value">{dateValue.toFormat('yyyy-LL-dd')}</div>
					<Button
						size="small"
						icon="chevron-right"
						onClick={() => adjustDays(1)}
						disabled={dateValue >= DateTime.now().startOf('day')}
					/>
				</div>
				<div className="charts">{children}</div>
			</ChartGroupContext.Provider>
		</div>
	)
}

export const useChartGroupContext = () => useContext(ChartGroupContext)
