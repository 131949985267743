/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const SelectionBox: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="selection-box" ref={ref}  {...props}>
            <path
                d={isLarge ? "M100 400H40H20C8.9543 400 0 391.0457 0 380V360V300H40L40 360H100V400zM360 100V40H300V0H360H380C391.046 0 400 8.954 400 20V40V100H360zM0 40V100H40L40 40H100V0H40H20C8.9543 0 0 8.954 0 20V40zM300 400H360H380C391.046 400 400 391.0457 400 380V360V300H360V360H300V400zM160 400H240V360H160V400zM360 240V160H400V240H360zM0 160V240H40L40 160H0zM240 40H160V0H240V40z" : "M200 280L120 280V320H200V280zM0 200V120H40V200H0zM120 0H200V40H120V0zM280 120V200H320V120H280zM40 320H20C8.9543 320 0 311.0457 0 300V280V240H40V280L80 280V320H40zM0 20V40V80H40L40 40H80V0H40H20C8.9543 0 0 8.954 0 20zM280 0H300C311.046 0 320 8.954 320 20V40V80H280V40H240V0H280zM320 300V280V240H280V280H240V320H280H300C311.046 320 320 311.0457 320 300z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
SelectionBox.defaultProps = {
    size: IconSize.STANDARD,
};
SelectionBox.displayName = `Blueprint5.Icon.SelectionBox`;
export default SelectionBox;
