import './index.scss'

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import Lang from '../../../../Lang'
import { isAdmin } from '../../../../Util'
import { Button, Tab, Tabs } from '@blueprintjs/core'
import AdminTabPanel from './Tabs/Admin'
import GeneralTabPanel from './Tabs/General'
import HistoryTabPanel from './Tabs/History'
import EventsTabPanel from './Tabs/Events'
import API from '../../../../API'

export const baseKeyDetails = {
	energyConsumption: { label: Lang.get('Consumption'), unit: 'kWh' },

	historicalConsumption: { label: Lang.get('Historical consumption'), unit: 'kWh' },
	historicalCO2: { label: Lang.get('Historical CO2'), unit: 'kg' },
	savedEnergy: { label: Lang.get('Saved energy'), unit: 'kWh' },
	savedCO2: { label: Lang.get('Saved CO2'), unit: 'kg' },

	trafficIntensity: { label: Lang.get('Traffic'), unit: '%' },
	logCount: { label: Lang.get('Report count') },
	onlinePercentage: { label: Lang.get('Online devices'), unit: '%' },
	profileFinishChunkingCount: { label: Lang.get('Profile downloads') }
}

export const modbusKeyDetails = {
	consumption_mb: { label: Lang.get('MB Consumption') },
	voltage_mb: { label: Lang.get('MB Voltage') },
	current_mb: { label: Lang.get('MB Current') },
	activePower_mb: { label: Lang.get('MB Active power') },
	reactivePower_mb: { label: Lang.get('MB Reactive power') },
	apparentPower_mb: { label: Lang.get('MB Apparent power') },
	totalActiveEnergy_mb: { label: Lang.get('MB Total active energy') }
}

export const d4iKeyDetails = {
	activePower: { label: Lang.get('Active Power'), unit: 'W' },
	lampShortAddress: { label: Lang.get('Lamp Short Address'), unit: '' },
	nominalPower: { label: Lang.get('Nominal Power'), unit: 'W', format: (v) => (v == 65535 ? '-' : v) },
	minDimLevelFromDriver: { label: Lang.get('Min Dim Level From Driver'), unit: '', type: 'dalivalue' },
	currentDimLevelFromSchedule: { label: Lang.get('Current Dim Level'), unit: '', type: 'dalivalue' },
	totalActiveEnergy: { label: Lang.get('Total Active Energy'), unit: 'kWh', format: (v) => v.toFixed(3) },
	driverOnTime: { label: Lang.get('Driver On Time'), unit: 's', type: 'duration' },
	mainsVoltage: { label: Lang.get('Mains Voltage'), unit: 'V', format: (v) => (v / 10).toFixed(1) },
	mainsCurrent: { label: Lang.get('Mains Current'), unit: 'mA' },
	powerFactor: { label: Lang.get('Power Factor'), unit: '', format: (v) => (v / 100).toFixed(2) },
	frequency: { label: Lang.get('Frequency'), unit: 'Hz' },
	driverFailureFlags: { label: Lang.get('Driver Failure Flags'), unit: '' },
	driverTemperature: { label: Lang.get('Driver Temperature'), unit: '°C' },
	ledOnTime: { label: Lang.get('LED On Time'), unit: 's', type: 'duration' },
	ledFailureFlags: { label: Lang.get('LED Failure Flags'), unit: '' },
	ledTemperature: { label: Lang.get('LED Temperature'), unit: '°C', format: (v) => (v < -60 ? '-' : v) },
	ledVoltage: { label: Lang.get('LED Voltage'), unit: 'V', format: (v) => (v / 10).toFixed(1) },
	ledCurrent: { label: Lang.get('LED Current'), unit: 'mA' }
}

export const keyDetails = {
	...baseKeyDetails,
	...modbusKeyDetails,
	...d4iKeyDetails
}

// =============================================================================

const DeviceDetailsContext = createContext(null)

export const useDeviceDetailsContext = () => useContext(DeviceDetailsContext)

export default function DeviceDetails({ device, view }) {
	const activeEvents = device.events?.filter((e) => e.isActive) ?? []
	const hasErrors = activeEvents?.some((e) => e.level === 'ERROR')

	const [selectedTabId, setSelectedTabId] = useState('general')

	const [isLoadingAdditionalDetails, setIsLoadingAdditionalDetails] = useState(true)
	const [additionalDetails, setAdditionalDetails] = useState(null)

	const fetchAdditionalDetails = useCallback(async (deviceId) => {
		setIsLoadingAdditionalDetails(true)

		const res = await API.call('Device.GetAdditionalDetails', {
			deviceId
		})

		if (res.success) {
			setAdditionalDetails(res.details)
		} else {
			console.error('Error fetching additional details:', res)
		}

		setIsLoadingAdditionalDetails(false)
	}, [])

	useEffect(() => {
		fetchAdditionalDetails(device.id)

		return () => {
			setIsLoadingAdditionalDetails(true)
			setAdditionalDetails(null)
		}
	}, [device.id])

	return (
		<div className="DeviceDetails" key={device.id}>
			<div className="closeButton">
				<Button icon="cross" variant="minimal" intent="danger" onClick={() => view.selectDevice(null)} />
			</div>
			{/* <div className="modelBlock">
				<span>{Lang.get('C4 Controller')}</span>
			</div> */}
			<div className="idBlock">
				<span>{device.id}</span>
			</div>

			<DeviceDetailsContext.Provider value={{ device, view, isLoadingAdditionalDetails, additionalDetails }}>
				<Tabs className="sectionTabs" onChange={(tab) => setSelectedTabId(tab)} selectedTabId={selectedTabId}>
					<Tab id="general" title={Lang.get('General')} panel={<GeneralTabPanel view={view} />} />

					<Tab id="history" title={Lang.get('History')} panel={<HistoryTabPanel device={device} />} />

					<Tab
						id="events"
						title={Lang.get('Events')}
						panel={<EventsTabPanel device={device} />}
						{...(activeEvents.length > 0
							? {
									className: `has-active-events ${hasErrors ? 'has-errors' : ''}`,
									tagContent: activeEvents.length
							  }
							: {})}
						tagProps={{
							intent: activeEvents.length > 0 ? (hasErrors ? 'danger' : 'warning') : 'none',
							style: {
								textAlign: 'center'
							}
						}}
					/>

					{isAdmin() && <Tab id="admin" title="Admin" panel={<AdminTabPanel />} />}
				</Tabs>
			</DeviceDetailsContext.Provider>
		</div>
	)
}

export function DeviceDetailsBlock({ className, title, loading, children, isDanger = false, ...rest }) {
	const cname = ['DeviceDetailsBlock', 'container-shadow']
	if (className) {
		cname.push(className)
	}

	if (loading) {
		cname.push('loading')
	}

	if (isDanger) {
		cname.push('danger-block')
	}

	return (
		<div className={cname.join(' ')} {...rest}>
			{title && <div className="heading">{title}</div>}
			<div className="content">{children}</div>
		</div>
	)
}
