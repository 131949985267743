import './index.scss'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DeviceDetailsBlock, keyDetails, useDeviceDetailsContext } from '../..'
import DeviceChoice from '../../../DeviceChoice'
import API, { RFCommand, RFPacket } from '../../../../../../API'
import { Button, InputGroup, MenuItem, Spinner } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import { AdminOnly, dali2percents, DEVICE_MODES, getAgoText, isAdmin, isInspector } from '../../../../../../Util'
import Lang from '../../../../../../Lang'
import Time from '../../../../../../Components/Time'
import { DateTime } from 'luxon'
import Countdown from 'react-countdown'
import DaliOverrideDialog from './Components/DaliOverrideDialog'
import { useAppContext } from '../../../../../../Components/App'
import ProfileIcon from '../../../../../../Components/CustomIcons/ProfileIcon'
import IconTextBox from '../../../../../../Components/IconTextBox'
import {
	getFillColorForDeviceGlowStatus,
	getRingColorForDevice,
	GLOW_STATUS_LABELS,
	ONLINE_STATUS_LABELS
} from '../../../../../../Components/ILMap'
import StatusRingIcon from '../../../../../../Components/CustomIcons/StatusRingIcon'
import GlowCircleIcon from '../../../../../../Components/CustomIcons/GlowCircleIcon'

export default function GeneralTabPanel({ view }) {
	const { device } = useDeviceDetailsContext()

	return (
		<>
			{(isInspector() || isAdmin()) && <DeviceDetailsActionBlock device={device} selectDevice={view.selectDevice} />}
			<DeviceDetailsInfoBlock device={device} view={view} />

			{device.mode == DEVICE_MODES.SEGMENT ? (
				<DeviceChildrenBlock device={device} view={view} />
			) : (
				<DeviceNeighboursBlock device={device} view={view} />
			)}

			<DeviceDetailsD4IBlock />
		</>
	)
}

function DeviceDetailsD4IBlock() {
	const { additionalDetails, isLoadingAdditionalDetails } = useDeviceDetailsContext()

	const hasFinishedLoading = !isLoadingAdditionalDetails && additionalDetails != null

	if (!hasFinishedLoading) {
		return <Spinner size={20} className="loading-spinner" />
	}

	const d4i = additionalDetails?.lastCommands?.find((c) => c.commandName == 'D4I')
	if (!d4i) {
		return null
	}

	if (d4i.payload) {
		d4i.payload.activePower = (
			(d4i.payload.mainsVoltage / 10) *
			(d4i.payload.mainsCurrent / 1000) *
			(d4i.payload.powerFactor / 100)
		).toFixed(2)

		// some weird d4i allegedly?
		if (d4i.payload.subCommandName != 'MEASUREMENT_REPORT') {
			return null
		}
	}

	return (
		<DeviceDetailsBlock title="D4I Report">
			<div className="reportLine">
				<span>Received</span>
				<span className="value">
					<Time timestamp={d4i.time / 1000} />
				</span>
			</div>
			{Object.keys(keyDetails).map((key) => {
				const value = d4i.payload[key]
				if (value === undefined) {
					return null
				}
				const keyData = keyDetails[key]
				let formattedValue = value
				if (keyData.format) {
					formattedValue = keyData.format(value)
				}
				if (keyData.type == 'duration') {
					formattedValue = (value / 3600).toFixed(0) + ' h'
				} else if (keyData.type == 'dalivalue') {
					formattedValue = /*'(' + value + ') ' + */ dali2percents(value) + '%'
				}
				return (
					<div className="reportLine" key={`report-line-${key}`}>
						<span>{keyData.label}</span>
						<span className="value">
							{formattedValue} {keyData.unit}
						</span>
					</div>
				)
			})}
		</DeviceDetailsBlock>
	)
}

function DeviceNeighboursBlock({ device, view }) {
	const [busy, setBusy] = useState(false)

	const neighbourIds = device.neighbours.map((d) => d.id)

	async function saveNeighbours(newNeighbourIds) {
		const added = newNeighbourIds.filter((id) => !neighbourIds.includes(id))
		const removed = neighbourIds.filter((id) => !newNeighbourIds.includes(id))

		if (added.length == 0 && removed.length == 0) return

		setBusy(true)

		await view.setNeighbours(device, [
			...added.map((id) => ({ id, multiplier: 1 })),
			...removed.map((id) => ({ id, multiplier: 0 }))
		])

		setBusy(false)
	}

	return (
		<DeviceDetailsBlock title={Lang.get('Neighbour Lights')} loading={busy}>
			<DeviceChoice deviceIds={neighbourIds} hideDeviceIds={[device.id]} onChange={saveNeighbours} />
		</DeviceDetailsBlock>
	)
}

function DeviceChildrenBlock({ device, view }) {
	const { devices } = useAppContext()

	const [busy, setBusy] = useState(false)

	const childrenIds = useMemo(() => devices.filter((d) => d.parentSegmentId == device.id).map((d) => d.id), [devices])

	async function saveParentSegmentIds(newChildrenIds) {
		const added = newChildrenIds.filter((id) => !childrenIds.includes(id))
		const removed = childrenIds.filter((id) => !newChildrenIds.includes(id))

		if (added.length == 0 && removed.length == 0) return

		setBusy(true)

		await view.setSegmentChildren(device, newChildrenIds)

		setBusy(false)
	}

	return (
		<DeviceDetailsBlock title={Lang.get('Segment Controlled Devices')} loading={busy}>
			<DeviceChoice
				deviceIds={childrenIds}
				hideDeviceIds={[device.id, ...devices.filter((d) => d.mode === '2').map((d) => d.id)]}
				onChange={saveParentSegmentIds}
			/>
		</DeviceDetailsBlock>
	)
}

// =============================================================================

function DeviceDetailsInfoBlock({ device: initialDevice, view }) {
	const { profiles, devices, digestServerUpdate } = useAppContext()

	const [busy, setBusy] = useState(false)
	const [deviceData, setDeviceData] = useState(initialDevice)
	const [deviceUnsaved, setDeviceUnsaved] = useState(false)

	const deviceProfile = useMemo(() => profiles.find((p) => p.id == deviceData.lightingProfileId), [deviceData.lightingProfileId])

	useEffect(() => {
		if (initialDevice.id !== deviceData.id) {
			setDeviceData(initialDevice)
		}
	}, [initialDevice.id])

	function updateDeviceValue(key, value) {
		setDeviceData((prev) => {
			if (prev[key] == null && value == '') return prev

			if (prev[key] !== value) {
				setDeviceUnsaved(true)

				return {
					...prev,
					[key]: value
				}
			}

			return prev
		})
	}

	async function save() {
		setBusy(true)

		const deviceUpdateData = {
			id: deviceData.id,
			info: deviceData.info,
			title: deviceData.title,
			properties: deviceData.properties,
			parentSegmentId: deviceData.parentSegmentId
		}

		const saveDevice = async () => {
			const res = await API.call('Device.Update', deviceUpdateData)
			return res
		}

		const wantsRemoveProfile = deviceData.lightingProfileId == -1 || deviceData.lightingProfileId == null
		const wantsChangeProfile = deviceData.lightingProfileId != initialDevice.lightingProfileId && !wantsRemoveProfile
		if (wantsChangeProfile) {
			await API.call('LightingProfile.AddDevice', { deviceId: deviceData.id, profileId: deviceData.lightingProfileId })
		} else if (wantsRemoveProfile) {
			await API.call('Device.RemoveProfile', { deviceId: deviceData.id })
		}

		const res = await saveDevice()
		if (res.success) {
			setDeviceUnsaved(false)

			const newDevice = {
				...initialDevice,
				...deviceUpdateData,
				...(wantsChangeProfile || wantsRemoveProfile
					? { lightingProfileId: wantsChangeProfile ? deviceData.lightingProfileId : null }
					: {})
			}

			view.setDevice(newDevice)
			view.selectDevice(newDevice)

			if (res.update) {
				digestServerUpdate(res.update)
			}
		}

		setBusy(false)
	}

	return (
		<DeviceDetailsBlock className="device-info-block">
			<div>
				<div>
					{Lang.get('Last Heard')}:{' '}
					<span className="value">
						<Time timestamp={deviceData.lastDataTime} />
					</span>
				</div>

				<div>{getAgoText(DateTime.fromSeconds(deviceData.lastDataTime ?? -1))}</div>
			</div>

			<IconTextBox iconElement={<ProfileIcon profileColor={deviceProfile?.color ?? 'gray'} size={1.25} />}>
				<div className="bp5-html-select" style={{ width: '100%' }}>
					<select
						onChange={(e) => updateDeviceValue('lightingProfileId', parseInt(e.target.value ?? -1))}
						value={deviceData.lightingProfileId ?? -1}
						disabled={busy}>
						<option value={-1}>None</option>
						{profiles.map((p) => (
							<option value={p.id} key={`lp-option${p.id}`}>
								{p.title}
							</option>
						))}
					</select>
					<span className="bp5-icon bp5-icon-double-caret-vertical" style={{ top: '50%', transform: 'translateY(-50%)' }} />
				</div>
			</IconTextBox>

			<IconTextBox
				iconElement={<StatusRingIcon color={getRingColorForDevice(initialDevice)} size={1.25} />}
				contentStyle={{ height: '30px' }}>
				{ONLINE_STATUS_LABELS[initialDevice.status.onlineStatus]}
			</IconTextBox>

			<IconTextBox
				iconElement={
					<GlowCircleIcon
						color={getFillColorForDeviceGlowStatus(initialDevice)}
						size={1.25}
						style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
					/>
				}
				contentStyle={{ height: '30px' }}>
				{GLOW_STATUS_LABELS[initialDevice.status.currentGlow]}
			</IconTextBox>

			<div>
				<span>{Lang.get('Title')}: </span>
				<InputGroup
					disabled={busy || (!isAdmin() && !isInspector())}
					value={deviceData.title ?? ''}
					onChange={(e) => updateDeviceValue('title', e.target.value)}
				/>
			</div>

			<AdminOnly>
				<div>
					<span>{Lang.get('Info')}: </span>
					<InputGroup disabled={busy} value={deviceData.info ?? ''} onChange={(e) => updateDeviceValue('info', e.target.value)} />
				</div>

				<div>
					<span>{Lang.get('Nominal Power')}: </span>
					<InputGroup
						disabled={busy}
						value={deviceData.properties?.nominalPower ?? ''}
						onChange={(e) => updateDeviceValue('properties', { ...deviceData.properties, nominalPower: e.target.value })}
					/>
				</div>
				<div>
					<span>{Lang.get('Historical nominal power')}: </span>
					<InputGroup
						disabled={busy}
						value={deviceData.properties?.historicalNominalPower ?? ''}
						onChange={(e) =>
							updateDeviceValue('properties', { ...deviceData.properties, historicalNominalPower: e.target.value })
						}
					/>
				</div>

				{deviceData.firmwareVersion && (
					<div>
						<span>{Lang.get('Firmware')}: </span>
						<span className="value">{deviceData.firmwareVersion}</span>
					</div>
				)}
			</AdminOnly>

			{initialDevice.mode != 2 && (
				<div>
					<span>{Lang.get('Segment ID')}: </span>
					<Suggest
						defaultSelectedItem={deviceData.parentSegmentId}
						items={[
							'',
							...devices
								.filter((d) => d?.mode == 2)
								.map((d) => d.id)
								.filter((id) => id != initialDevice.id)
						]}
						onItemSelect={(id) => updateDeviceValue('parentSegmentId', id)}
						itemRenderer={(item, { handleClick }) => {
							if (item !== '') {
								return <MenuItem key={item} text={item} onClick={handleClick} />
							}

							return <MenuItem key={`empty-segment-item`} text={Lang.get('None')} onClick={handleClick} />
						}}
						itemPredicate={(query, item) => item.toString().includes(query)}
						inputValueRenderer={(item) => item}
						noResults={null}
						resetOnQuery={false}
						inputProps={{ placeholder: Lang.get('Search...') }}
					/>
				</div>
			)}

			{deviceUnsaved && (
				<div>
					<Button
						disabled={busy}
						icon={busy ? <Spinner className="spinner-icon white" /> : 'floppy-disk'}
						text={Lang.get('Save')}
						onClick={save}
						fill
						intent="primary"
					/>
				</div>
			)}
		</DeviceDetailsBlock>
	)
}

function DeviceDetailsActionBlock({ device, selectDevice }) {
	const [busyTarget, setBusyTarget] = useState([])

	function addBusyTarget(target) {
		setBusyTarget((curBusyTarget) => {
			if (curBusyTarget.includes(target)) return curBusyTarget
			return [...curBusyTarget, target]
		})
	}

	function clearBusyTarget(target) {
		setBusyTarget((curBusyTarget) => {
			return curBusyTarget.filter((t) => t != target)
		})
	}

	const [isDaliOverrideDialogOpen, setIsDaliOverrideDialogOpen] = useState(false)

	const [daliOverrideEndTimestamp, setDaliOverrideEndTimestamp] = useState(device.lastDaliOverrideEndTime)
	const [daliOverrideBrightness, setDaliOverrideBrightness] = useState(device.lastDaliOverrideValue)

	const timeoutRef = useRef(null)
	useEffect(() => {
		if (daliOverrideEndTimestamp > 0) {
			const timeout = daliOverrideEndTimestamp - DateTime.now().toUnixInteger()
			if (timeout > 0) {
				timeoutRef.current = setTimeout(() => {
					setDaliOverrideEndTimestamp(0)
				}, timeout * 1000)
			} else {
				setDaliOverrideEndTimestamp(0)
			}
		}

		return () => {
			clearTimeout(timeoutRef.current)
		}
	}, [daliOverrideEndTimestamp])

	async function dispatchAction(action) {
		switch (action) {
			case 'clear-dali-override': {
				addBusyTarget('clear-dali-override')

				const res = await API.call('Device.ClearDaliOverride', {
					deviceId: device.id
				})

				if (res.success) {
					setDaliOverrideEndTimestamp(0)
				} else {
					console.error('Failed to clear DALI override', res)
				}

				clearBusyTarget('clear-dali-override')
				break
			}
			case 'blink': {
				addBusyTarget('blink')

				const p = new RFPacket()
				p.command = RFCommand.IDENTIFY
				await API.sendPacket(p, device)

				clearBusyTarget('blink')
				break
			}
			default: {
				console.error('Unknown action', action)
				return
			}
		}
	}

	const daliOverrideBtn = (
		<>
			{daliOverrideEndTimestamp == 0 ? (
				<Button
					icon="wrench-time"
					disabled={busyTarget.includes('set-dali-override')}
					text="Set DALI override"
					onClick={() => setIsDaliOverrideDialogOpen(true)}
				/>
			) : (
				<>
					<Button
						icon="wrench-redo"
						disabled={busyTarget.includes('clear-dali-override')}
						onClick={() => dispatchAction('clear-dali-override')}
						intent="success"
						style={{ textAlign: 'center' }}>
						<Countdown
							date={daliOverrideEndTimestamp * 1000}
							renderer={({ hours, minutes, seconds }) => {
								let str = ''
								if (hours > 0) {
									str += hours + 'h '
								}

								if (minutes > 0) {
									str += minutes + 'm '
								}

								str += seconds + 's left at ' + daliOverrideBrightness + '%'

								return (
									<div>
										Clear DALI override <br />
										{str}
									</div>
								)
							}}
						/>
					</Button>
				</>
			)}
		</>
	)

	return (
		<>
			<DeviceDetailsBlock title={Lang.get('Actions')} className="actions">
				<DeviceDetailsPingButton device={device} />
				<Button icon="flash" disabled={busyTarget.includes('blink')} text="Blink DALI" onClick={() => dispatchAction('blink')} />
				{daliOverrideBtn}
			</DeviceDetailsBlock>

			<DaliOverrideDialog
				deviceId={device.id}
				open={isDaliOverrideDialogOpen}
				onOpenChange={setIsDaliOverrideDialogOpen}
				onSuccess={({ endTimestamp, brightness }) => {
					setDaliOverrideEndTimestamp(endTimestamp)
					setDaliOverrideBrightness(brightness)
				}}
			/>
		</>
	)
}

export class DeviceDetailsPingButton extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false, response: null }
	}

	async ping() {
		if (this.state.busy) return
		this.setState({ busy: true, response: null })

		const r = await API.call('Device.Ping', { deviceId: this.props.device.id })

		if (r.success) {
			this.setState({ busy: false, response: r.timeMs })
		} else {
			this.setState({ busy: false, response: -1 })
		}
	}

	render() {
		if (this.state.busy) {
			return <Button loading />
		} else {
			if (this.state.response) {
				if (this.state.response == -1) {
					return <Button icon="feed" intent="danger" text="Ping Failed" onClick={() => this.ping()} />
				} else {
					return (
						<Button icon="feed" intent="success" text={'Pinged in ' + this.state.response + ' ms'} onClick={() => this.ping()} />
					)
				}
			} else {
				return <Button icon="feed" text={Lang.get('Ping')} onClick={() => this.ping()} />
			}
		}
	}
}
