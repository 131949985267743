import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import Lang from '../../../../../Lang'
import { DateInput2, TimePicker } from '@blueprintjs/datetime2'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Button, ButtonGroup, InputGroup, Switch, Menu, MenuItem, NumericInput, Checkbox } from '@blueprintjs/core'
import { clamp, dateToSecondsToday, secondsTodayToDate } from '../../../../../Util'
import { placeIdsOnPeriods, shifterizeBrightnessLevels, unshifterizeBrightnessLevels } from '..'

// ===================================================================

const conditionTypes = [
	{
		type: 'weekdays',
		label: Lang.get('Weekdays'),
		icon: 'fa-calendar-clock',
		incompatibleWith: ['weekdays'],
		defaultValue: []
	},
	{
		type: 'nightlight',
		label: Lang.get('Nightlight'),
		icon: 'fa-moon',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'daylight',
		label: Lang.get('Daylight'),
		icon: 'fa-sun',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'date',
		label: Lang.get('Date'),
		icon: 'fa-calendar',
		incompatibleWith: ['date'],
		defaultValue: DateTime.now().toFormat('yyyy-LL-dd')
	}
]

const MIN_PERIOD_DURATION = 900

// ===================================================================

export default function ConditionBlock({ lightingProfileId, blockData, blockAction, sunriseSunsetTimes }) {
	const { sunrise, sunset } = sunriseSunsetTimes
	const sunriseOffset = blockData.conditions.daylight?.sunriseOffset ?? blockData.conditions.nightlight?.sunriseOffset ?? 0
	const sunsetOffset = blockData.conditions.daylight?.sunsetOffset ?? blockData.conditions.nightlight?.sunsetOffset ?? 0

	function addCondition(conditionType) {
		const condition = conditionTypes.find((c) => c.type == conditionType)
		if (!condition) {
			return
		}
		if (condition.incompatibleWith.some((c) => blockData.conditions[c])) {
			alert(Lang.get('This condition is incompatible with existing conditions'))
			return
		}

		blockAction('set-value', { conditions: { ...blockData.conditions, [condition.type]: condition.defaultValue } })
	}

	function removeCondition(conditionType) {
		const newConditions = { ...blockData.conditions }
		delete newConditions[conditionType]
		blockAction('set-value', { conditions: newConditions })
	}

	// const [brightnessLevels, setBrightnessLevels] = useState([
	// 	{ id: 0, start: 0, idleBrightness: 0.15, activeBrightness: 0.25 },
	// 	{ id: 1, start: 21600, idleBrightness: 0.25, activeBrightness: 0.35 },
	// 	{ id: 2, start: 43200, idleBrightness: 0.35, activeBrightness: 0.45 },
	// 	{ id: 3, start: 64800, idleBrightness: 0.45, activeBrightness: 0.55 }
	// ])

	function setBrightnessLevels(newPeriods) {
		blockAction('set-value', { brightnessLevels: newPeriods })
	}

	const last1212Value = useRef(blockData.is1212Mode)

	useMemo(() => {
		last1212Value.current = blockData.is1212Mode
	}, [lightingProfileId])

	// SET FAKE BLOCKS FROM CONDITIONS
	useEffect(() => {
		const conds = structuredClone(blockData.conditions)

		// they aint compatible 2gether so we can coalesce
		const realSunrise = sunrise + sunriseOffset * 60
		const realSunset = sunset + sunsetOffset * 60

		let newPeriods = structuredClone(blockData.brightnessLevels)

		// if 1212 mode changed
		if (last1212Value.current !== blockData.is1212Mode) {
			console.log('1212 mode changed', blockData.is1212Mode)
			if (blockData.is1212Mode) {
				newPeriods = shifterizeBrightnessLevels(newPeriods)
			} else {
				newPeriods = unshifterizeBrightnessLevels(newPeriods)
			}

			// created by shifterizing and shit
			const midStartBlockIdx = newPeriods.findIndex((p) => p.start === 43200 && !p.fake)
			if (midStartBlockIdx !== -1) {
				const beforeMidStart = newPeriods[midStartBlockIdx - 1]
				const afterMidStart = newPeriods[midStartBlockIdx]

				if (beforeMidStart && afterMidStart) {
					if (
						beforeMidStart.idleBrightness === afterMidStart.idleBrightness &&
						beforeMidStart.activeBrightness === afterMidStart.activeBrightness
					) {
						newPeriods.splice(midStartBlockIdx, 1)
						placeIdsOnPeriods(newPeriods)
					}
				}
			}

			last1212Value.current = blockData.is1212Mode
		}

		if (conds.nightlight || conds.daylight) {
			// clear
			const hasSetFakeBlocks = newPeriods.some((p) => p.fake)
			if (hasSetFakeBlocks) {
				newPeriods = newPeriods.filter((p) => !p.fake)

				if (newPeriods[0].start != 0) {
					newPeriods[0].start = 0
				}
			}

			// set
			if (conds.nightlight) {
				// block in middle
				if (!blockData.is1212Mode) {
					const fakeBlock = {
						start: realSunrise,
						idleBrightness: 0,
						activeBrightness: 0,
						fake: true
					}

					// remove any that start + duration > fakeBlock.start and start + duration < fakeBlockEnd
					// adjust start of those where start < fakeBlockEnd
					const index = newPeriods.findIndex((p) => p.start >= fakeBlock.start)
					if (index === -1) {
						newPeriods = [{ ...newPeriods[0] }, fakeBlock, { ...newPeriods[0], start: realSunset }]
					} else {
						newPeriods = newPeriods.filter((p, idx) => {
							if (p.fake) return true

							const next = newPeriods[idx + 1]
							const dur = (next?.start ?? 86400) - p.start

							if ((p.start >= realSunrise && p.start + dur <= realSunset) || dur < MIN_PERIOD_DURATION) {
								return false
							}

							return true
						})

						newPeriods.splice(index, 0, fakeBlock)
						newPeriods[index + 1].start = realSunset
					}
				}

				// blocks on sides
				if (blockData.is1212Mode) {
					// from 0 until sunset
					const fakeBlockLeftDuration = realSunset - 43200
					const fakeBlockLeft = {
						start: 0,
						idleBrightness: 0,
						activeBrightness: 0,
						fake: true
					}

					// from sunrise until 86400
					const fakeBlockRightStart = realSunrise + 43200
					const fakeBlockRight = {
						start: fakeBlockRightStart,
						idleBrightness: 0,
						activeBrightness: 0,
						fake: true
					}

					// remove any that start < fakeBlockLeftDuration or start > fakeBlockRightStart
					// adjust start of those where start + duration > fakeBlockLeftDuration
					newPeriods = newPeriods.filter((p, idx) => {
						if (p.fake) return true

						const next = newPeriods[idx + 1]
						const dur = (next?.start ?? 86400) - p.start

						if (p.start + dur <= fakeBlockLeftDuration || p.start >= fakeBlockRightStart || dur < MIN_PERIOD_DURATION) {
							return false
						}

						return true
					})

					newPeriods.forEach((p) => {
						if (p.start < fakeBlockLeftDuration) {
							p.start = fakeBlockLeftDuration
						}
					})

					newPeriods = [fakeBlockLeft, ...newPeriods, fakeBlockRight]
				}
			}

			if (conds.daylight) {
				// blocks on sides
				if (!blockData.is1212Mode) {
					const fakeBlockLeftDuration = realSunrise
					const fakeBlockLeft = {
						start: 0,
						idleBrightness: 0,
						activeBrightness: 0,
						fake: true
					}

					const fakeBlockRightStart = realSunset
					const fakeBlockRight = {
						start: fakeBlockRightStart,
						idleBrightness: 0,
						activeBrightness: 0,
						fake: true
					}

					// remove any that start < fakeBlockLeftDuration or start > fakeBlockRightStart
					// adjust start of those where start + duration > fakeBlockLeftDuration
					newPeriods = newPeriods.filter((p, idx) => {
						if (p.fake) return true

						const next = newPeriods[idx + 1]
						const dur = (next?.start ?? 86400) - p.start

						if (p.start + dur <= fakeBlockLeftDuration || p.start >= fakeBlockRightStart || dur < MIN_PERIOD_DURATION) {
							return false
						}

						return true
					})

					newPeriods.forEach((p) => {
						if (p.start < fakeBlockLeftDuration) {
							p.start = fakeBlockLeftDuration
						}
					})

					newPeriods = [fakeBlockLeft, ...newPeriods, fakeBlockRight]
				}

				// block in middle
				if (blockData.is1212Mode) {
					const fakeBlockEnd = realSunrise + 43200
					const fakeBlock = {
						start: realSunset - 43200,
						idleBrightness: 0,
						activeBrightness: 0,
						fake: true
					}

					// remove any that start + duration > fakeBlock.start and start + duration < fakeBlockEnd
					// adjust start of those where start < fakeBlockEnd
					const index = newPeriods.findIndex((p) => p.start >= fakeBlock.start)
					if (index === -1) {
						newPeriods = [{ ...newPeriods[0] }, fakeBlock, { ...newPeriods[0], start: fakeBlockEnd }]
					} else {
						newPeriods = newPeriods.filter((p, idx) => {
							if (p.fake) return true

							const next = newPeriods[idx + 1]
							const dur = (next?.start ?? 86400) - p.start

							if ((p.start >= fakeBlock.start && p.start + dur <= fakeBlockEnd) || dur < MIN_PERIOD_DURATION) {
								return false
							}

							return true
						})

						newPeriods.splice(index, 0, fakeBlock)
						newPeriods[index + 1].start = fakeBlockEnd
					}
				}
			}
		} else {
			// clear
			const hasSetFakeBlocks = newPeriods.some((p) => p.fake)
			if (hasSetFakeBlocks) {
				const existingFakeBlocks = newPeriods.filter((p) => p.fake)
				newPeriods = newPeriods.filter((p) => !p.fake)

				if (newPeriods[0].start != 0) {
					newPeriods[0].start = 0
				}

				// wants merge back 2gether
				if (
					newPeriods.length === 2 &&
					newPeriods[0].idleBrightness === newPeriods[1].idleBrightness &&
					newPeriods[0].activeBrightness === newPeriods[1].activeBrightness
				) {
					newPeriods.splice(1, 1)
				} else {
					const [fakeBlock] = existingFakeBlocks
					const idxToMerge = newPeriods.findIndex((p) => p.id === fakeBlock.id + 1)
					newPeriods[idxToMerge].start = fakeBlock.start
				}
			}
		}

		newPeriods = newPeriods.filter((p, idx) => (newPeriods[idx + 1]?.start ?? 86400) - p.start > MIN_PERIOD_DURATION || p.fake)

		newPeriods.sort((a, b) => a.start - b.start)

		placeIdsOnPeriods(newPeriods)

		// wants fill beginning
		if (blockData.is1212Mode) {
			if (newPeriods[0].start != 0) {
				newPeriods.unshift({ ...structuredClone(newPeriods[newPeriods.length - 1]), start: 0 })
				placeIdsOnPeriods(newPeriods)

				// created by shifterizing and shit
				const midStartBlockIdx = newPeriods.findIndex((p) => p.start === 43200 && !p.fake)
				if (midStartBlockIdx !== -1) {
					const beforeMidStart = newPeriods[midStartBlockIdx - 1]
					const afterMidStart = newPeriods[midStartBlockIdx]

					if (beforeMidStart && afterMidStart) {
						if (
							beforeMidStart.idleBrightness === afterMidStart.idleBrightness &&
							beforeMidStart.activeBrightness === afterMidStart.activeBrightness
						) {
							newPeriods.splice(midStartBlockIdx, 1)
							placeIdsOnPeriods(newPeriods)
						}
					}
				}
			}
		}

		// // one block was split and now we need to merge
		// const hasFakeBlocksOnSides = newPeriods[0].fake && newPeriods[newPeriods.length - 1].fake
		// if (hasFakeBlocksOnSides && newPeriods.length === 4) {
		// 	if (
		// 		newPeriods[1].idleBrightness === newPeriods[2].idleBrightness &&
		// 		newPeriods[1].activeBrightness === newPeriods[2].activeBrightness
		// 	) {
		// 		newPeriods.splice(2, 1)
		// 	}
		// }

		setBrightnessLevels(newPeriods)
	}, [
		lightingProfileId,
		blockData.is1212Mode,
		Object.keys(blockData.conditions).length,
		sunriseOffset,
		sunsetOffset,
		blockData.brightnessLevels.length
	])

	function setPeriodValue(id, key, value) {
		const newPeriods = structuredClone(blockData.brightnessLevels)

		console.log(`setPeriodValue ::`, id, key, value)

		const prevBlock = newPeriods.find((p) => p.id === id - 1)
		const curBlock = newPeriods.find((p) => p.id === id)
		const nextBlock = newPeriods.find((p) => p.id === id + 1)

		if (!curBlock) {
			console.warn('setPeriodValue: invalid id', id, { prevBlock, curBlock, nextBlock })
			return
		}

		if (curBlock.fake) return

		switch (key) {
			case 'start': {
				// check backwards duration
				if (value - MIN_PERIOD_DURATION < (prevBlock?.start ?? 0)) {
					value = prevBlock.start + MIN_PERIOD_DURATION
				}

				// check forwards duration
				if (value + MIN_PERIOD_DURATION > (nextBlock?.start ?? 86400)) {
					value = (nextBlock?.start ?? 86400) - MIN_PERIOD_DURATION
				}

				curBlock.start = value

				break
			}

			// idleBrightness <= activeBrightness
			case 'idleBrightness': {
				if (value > curBlock.activeBrightness) {
					curBlock.activeBrightness = value
				}
				curBlock.idleBrightness = value

				break
			}

			// activeBrightness >= idleBrightness
			case 'activeBrightness': {
				if (value < curBlock.idleBrightness) {
					curBlock.idleBrightness = value
				}
				curBlock.activeBrightness = value

				break
			}

			default: {
				break
			}
		}

		// blockAction('set-value', { brightnessLevels: newPeriods })
		setBrightnessLevels(newPeriods)
	}

	function deletePeriod(id) {
		const newPeriods = structuredClone(blockData.brightnessLevels)
		if (newPeriods.length == 1) return

		let idx = newPeriods.findIndex((p) => p.id === id)
		const cur = newPeriods[idx]

		if (cur.fake) {
			if (blockData.conditions.nightlight) {
				removeCondition('nightlight')
			} else if (blockData.conditions.daylight) {
				removeCondition('daylight')
			}

			return
		}

		const prev = newPeriods[idx - 1]
		const next = newPeriods[idx + 1]

		if (prev?.fake && next?.fake) {
			console.log('Skipping delete: both sides fake blocks')
			return
		}

		// means block in middle and we need to check wtf we are deleting
		if ((blockData.is1212Mode && blockData.conditions.daylight) || (!blockData.is1212Mode && blockData.conditions.nightlight)) {
			if (prev?.fake) {
				if (next) {
					next.start = newPeriods[idx].start
				} else {
					console.log('Skipping delete: last block after fake')
					return
				}
			}
		}

		// means block in sides and we need to check wtf we are deleting
		if ((!blockData.is1212Mode && blockData.conditions.daylight) || (blockData.is1212Mode && blockData.conditions.nightlight)) {
			if (prev?.fake) {
				if (next) {
					next.start = newPeriods[idx].start
				} else {
					console.log('Skipping delete: one real block left')
					return
				}
			}
		}

		newPeriods.splice(idx, 1)

		placeIdsOnPeriods(newPeriods)

		if (newPeriods[0].start != 0) {
			newPeriods[0].start = 0
		}

		setBrightnessLevels(newPeriods)
	}

	function splitPeriod(id, extra = {}) {
		const newPeriods = structuredClone(blockData.brightnessLevels)

		const idx = newPeriods.findIndex((p) => p.id === id)
		const curBlock = newPeriods[idx]
		const nextBlock = newPeriods[idx + 1]

		if (!curBlock) {
			console.info('splitPeriod: invalid index', idx, { curBlock, nextBlock })
			return
		}

		delete curBlock.extendor

		const newBlock = {
			...curBlock,
			start: Math.round((curBlock.start + (nextBlock?.start ?? 86400)) / 2)
		}

		newPeriods.splice(idx + 1, 0, newBlock)

		placeIdsOnPeriods(newPeriods)

		setBrightnessLevels(newPeriods)
	}

	const menu = (
		<Menu>
			{conditionTypes.map((c, i) => (
				<MenuItem
					key={`cbct-${c.label}${i}`}
					icon={<i className={`fa-fw fa-solid ${c.icon}`} />}
					onClick={() => addCondition(c.type)}
					text={c.label}
				/>
			))}
		</Menu>
	)

	const hasConditions = Object.keys(blockData.conditions).length > 0

	return (
		<div className="ConditionBlock">
			<div className="header">
				<div className="padded">
					<Switch
						label={Lang.get('Active')}
						checked={blockData.active}
						onChange={(e) => blockAction('set-value', { active: e.target.checked })}
					/>
					<InputGroup
						size="small"
						placeholder={Lang.get('Note')}
						value={blockData.comment}
						onChange={(e) => blockAction('set-value', { comment: e.target.value })}
					/>
					<div>
						<ButtonGroup>
							<Tooltip2 content={Lang.get('Duplicate')}>
								<Button
									size="small"
									variant="minimal"
									icon={<i className="fa-light fa-clone"></i>}
									onClick={() => blockAction('duplicate')}
								/>
							</Tooltip2>
							<Tooltip2 content={Lang.get('Move up')}>
								<Button
									size="small"
									variant="minimal"
									icon={<i className="fa-solid fa-caret-up"></i>}
									onClick={() => blockAction('move-up')}
								/>
							</Tooltip2>
							<Tooltip2 content={Lang.get('Move down')}>
								<Button
									size="small"
									variant="minimal"
									icon={<i className="fa-solid fa-caret-down"></i>}
									onClick={() => blockAction('move-down')}
								/>
							</Tooltip2>
						</ButtonGroup>
						<Button
							size="small"
							variant="minimal"
							intent="danger"
							icon={<i className="fa-solid fa-xmark"></i>}
							onClick={() => blockAction('delete')}
						/>
					</div>
				</div>
			</div>

			<div className="padded">
				<div className="legend-block">
					<div>
						<Checkbox
							checked={blockData.is1212Mode !== undefined ? blockData.is1212Mode : false}
							label="12:00 - 12:00"
							onChange={(e) => blockAction('set-value', { is1212Mode: e.target.checked })}
						/>
					</div>
					<div className="DayBrightnessEditorLegend">
						<div className="daylight">{Lang.get('Daylight')}</div>
						<div className="idle">{Lang.get('No Motion')}</div>
						<div className="active">{Lang.get('Motion')}</div>
					</div>
				</div>

				<DayBrightnessEditor
					periods={blockData.brightnessLevels}
					conditions={blockData.conditions}
					sunrise={sunriseSunsetTimes.sunrise}
					sunset={sunriseSunsetTimes.sunset}
					setPeriodValue={setPeriodValue}
					splitPeriod={splitPeriod}
					deletePeriod={deletePeriod}
					is1212Mode={blockData.is1212Mode}
				/>
			</div>
			<div className="conditions">
				<h4>
					{Lang.get('Conditions')}
					<Popover2 content={menu} placement="bottom">
						<Button size="small" variant="minimal" intent="success" icon={<i className="fa-solid fa-plus"></i>} />
					</Popover2>
				</h4>

				{hasConditions &&
					Object.keys(blockData.conditions).map((conditionType) => {
						const conditionData = blockData.conditions[conditionType]
						return (
							<Condition
								conditionType={conditionType}
								data={conditionData}
								parentConditions={blockData.conditions}
								key={`cb-${conditionType}`}
								onRemove={() => {
									removeCondition(conditionType)
								}}
								onValueChange={(newValue) => {
									blockAction('set-value', { conditions: { ...blockData.conditions, [conditionType]: newValue } })
								}}
							/>
						)
					})}

				{!hasConditions && <div className="noConditions">{Lang.get('No conditions')}</div>}
			</div>
			<div className="brightness-table-wrapper">
				<table className="bp5-html-table bp5-compact bp5-html-table-bordered">
					<thead>
						<tr>
							<th></th>
							<th>{Lang.get('Start')}</th>
							<th>{Lang.get('No Motion')} %</th>
							<th>{Lang.get('Motion')} %</th>
							<th className="row-action-cell"></th>
						</tr>
					</thead>
					<tbody>
						{blockData.brightnessLevels.map((period, idx) => {
							const i = period.id

							const prevFake = blockData.brightnessLevels[i - 1]?.fake

							const isDayRow = blockData.conditions.nightlight && period.fake
							const isNightRow = blockData.conditions.daylight && period.fake

							return (
								<BrightnessTableRow
									key={`btr-${i ?? idx + 500}`}
									period={period}
									periods={blockData.brightnessLevels}
									onStartChange={(newSeconds) => setPeriodValue(i, 'start', newSeconds)}
									onIdleBrightnessChange={(valueNumber) =>
										!(valueNumber < 0 || valueNumber > 100) &&
										!isNaN(valueNumber) &&
										setPeriodValue(i, 'idleBrightness', valueNumber / 100)
									}
									onActiveBrightnessChange={(valueNumber) =>
										!(valueNumber < 0 || valueNumber > 100) &&
										!isNaN(valueNumber) &&
										setPeriodValue(i, 'activeBrightness', valueNumber / 100)
									}
									i={i}
									disabledStart={i == 0 || period.fake || prevFake}
									disabledValues={period.fake}
									className={`${isDayRow && 'dayRow'} ${isNightRow && 'nightRow'}`}
									splitPeriod={splitPeriod}
									deletePeriod={deletePeriod}
									is1212Mode={blockData.is1212Mode}
								/>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

// ===================================================================

function DayBrightnessEditor({ periods, sunrise, sunset, setPeriodValue, splitPeriod, deletePeriod, is1212Mode }) {
	const periodsRef = useRef(null)
	const targetInfoRef = useRef(null)
	const modeRef = useRef(null)

	function timeToPercentage(time) {
		return (time / 86400) * 100
	}

	function timeToString(time) {
		let t = DateTime.now().set({ hour: 0, minute: 0, second: time + (is1212Mode ? 43200 : 0) })
		return t.toFormat('HH:mm')
	}

	function infoForEvent(e) {
		const periodsBounds = periodsRef.current.getBoundingClientRect()

		const xPercentage = ((e.clientX - periodsBounds.left) / periodsBounds.width) * 100

		let yPercentage = ((e.clientY - periodsBounds.top) / periodsBounds.height) * 100
		yPercentage = Math.max(0, Math.min(100, yPercentage))
		yPercentage = 100 - yPercentage

		let activePeriod = null
		let activePeriodIndex = 0

		for (const [idx, period] of periods.entries()) {
			const nextPeriod = periods[idx + 1]
			const duration = (nextPeriod?.start ?? 86400) - period.start

			const start = timeToPercentage(period.start)
			const end = timeToPercentage(period.start + duration)

			if (start <= xPercentage && xPercentage <= end) {
				activePeriod = period
				break
			}
			activePeriodIndex++
		}

		return { period: activePeriod, periodIndex: activePeriodIndex, xPercentage, yPercentage }
	}

	function onMouseDown(e) {
		const info = infoForEvent(e)
		targetInfoRef.current = info

		if (e.target.classList.contains('activeBrightnessHandle')) {
			modeRef.current = 'activeBrightness'
		} else if (e.target.classList.contains('idleBrightnessHandle')) {
			modeRef.current = 'idleBrightness'
		} else if (e.target.classList.contains('horizontalResizeHandle')) {
			modeRef.current = 'horizontalResize'
		}
	}

	function onMouseUp() {
		modeRef.current = null
	}

	function onMouseMove(e) {
		if (modeRef.current && targetInfoRef.current) {
			const info = infoForEvent(e)

			let { periodIndex, period } = targetInfoRef.current

			console.log({ periodIndex, period })

			switch (modeRef.current) {
				case 'activeBrightness': {
					const newActiveBrightness = Math.round(info.yPercentage) / 100
					setPeriodValue(period.id, 'activeBrightness', newActiveBrightness)
					break
				}

				case 'idleBrightness': {
					const newIdleBrightness = Math.round(info.yPercentage) / 100
					setPeriodValue(period.id, 'idleBrightness', newIdleBrightness)
					break
				}

				case 'horizontalResize': {
					let targetId = period.id
					let newStart = Math.round((info.xPercentage / 100) * 86400)

					setPeriodValue(targetId + 1, 'start', newStart)
					break
				}
			}
		}
	}

	return (
		<div className="DayBrightnessEditor" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
			<div className="periods" ref={periodsRef}>
				<div className="ticker informative side" style={{ left: timeToPercentage(0) + '%' }}>
					{is1212Mode ? '12:00' : '00:00'}
				</div>

				<div className="ticker informative side" style={{ left: timeToPercentage(86400) + '%' }}>
					{is1212Mode ? '12:00' : '00:00'}
				</div>

				{!is1212Mode ? (
					<>
						<div
							className="sunsetSunriseOverlay"
							style={{
								left: timeToPercentage(sunrise) + '%',
								right: 100 - timeToPercentage(sunset) + '%'
							}}></div>
						<div className={`ticker informative`} style={{ left: timeToPercentage(sunrise) + '%' }}>
							<i className="fa-solid fa-sun-bright"></i>
							<span>{timeToString(sunrise)}</span>
						</div>
						<div className={`ticker informative`} style={{ left: timeToPercentage(sunset) + '%' }}>
							<i className="fa-solid fa-moon"></i>
							<span>{timeToString(sunset)}</span>
						</div>
					</>
				) : (
					<>
						<div
							className="sunsetSunriseOverlay left"
							style={{
								left: 0,
								right: 100 - timeToPercentage(sunset - 43200) + '%'
							}}></div>

						<div
							className="sunsetSunriseOverlay right"
							style={{
								right: 0,
								left: 100 - timeToPercentage(43200 - sunrise) + '%'
							}}></div>

						<div className={`ticker informative`} style={{ left: 100 - timeToPercentage(43200 - sunrise) + '%' }}>
							<i className="fa-solid fa-sun-bright"></i>
							<span>{timeToString(sunrise + 43200)}</span>
						</div>
						<div className={`ticker informative`} style={{ left: timeToPercentage(sunset - 43200) + '%' }}>
							<i className="fa-solid fa-moon"></i>
							<span>{timeToString(sunset + 43200)}</span>
						</div>
					</>
				)}

				{periods.map((period, i) => {
					const prevPeriod = periods[i - 1]
					const nextPeriod = periods[i + 1]
					const duration = (nextPeriod?.start ?? 86400) - period.start
					const isNextFake = nextPeriod && nextPeriod?.fake

					const isUnchangeable = period.fake || isNextFake

					return (
						<div
							key={`pp-${i}`}
							className="period"
							style={{ width: timeToPercentage(duration) + '%', left: timeToPercentage(period.start) + '%' }}>
							<div className="activeValueSlider" style={{ height: period.activeBrightness * 100 + '%' }}>
								<div className="handle activeBrightnessHandle" data-disabled={period.fake}>
									{Math.round(period.activeBrightness * 100)}%
								</div>
							</div>
							<div className="idleValueSlider" style={{ height: period.idleBrightness * 100 + '%' }}>
								<div className="handle idleBrightnessHandle" data-disabled={period.fake}>
									{Math.round(period.idleBrightness * 100)}%
								</div>
							</div>

							<div className="horizontalResizeHandle" data-disabled={isUnchangeable}>
								<div className={`ticker`} style={{ left: timeToPercentage(86400) + '%' }}>
									{timeToString(nextPeriod?.start ?? 86400)}
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<div className="tickers">
				{periods.map((period, i) => {
					const nextPeriod = periods[i + 1]
					const prevPeriod = periods[i - 1]

					const duration = (nextPeriod?.start ?? 86400) - period.start
					const isNextFake = nextPeriod && nextPeriod?.fake
					const isPrevFake = prevPeriod && prevPeriod?.fake

					return (
						<React.Fragment key={`tp-${i}`}>
							{/* <div
								className={'ticker' + (targetInfoRef.current?.periodIndex == i - 1 ? ' active' : '')}
								style={{ left: timeToPercentage(period.start) + '%' }}>
								{timeToString(period.start)}
							</div> */}
							{!period.fake && (
								<div
									className="tickerActionWrap"
									style={{
										left: timeToPercentage(period.start + duration / 2) + '%'
										// width: timeToPercentage(period.duration) + '%'
									}}>
									<div className="tickerAction">
										<ButtonGroup>
											{!(isPrevFake && isNextFake) &&
												!(i === 0 && isNextFake) &&
												!(i === periods.length - 1 && isPrevFake) &&
												periods.length != 1 && (
													<Button
														size="small"
														variant="minimal"
														intent="danger"
														icon={<i className="fa-solid fa-minus"></i>}
														onClick={() => deletePeriod(period.id)}
													/>
												)}
											<Button
												size="small"
												variant="minimal"
												intent="success"
												icon={<i className="fa-solid fa-plus"></i>}
												onClick={() => splitPeriod(period.id)}
											/>
										</ButtonGroup>
									</div>
								</div>
							)}
						</React.Fragment>
					)
				})}

				{/* <div className="ticker informative" style={{ left: timeToPercentage(86400) + '%' }}>
					{is1212Mode ? '12:00' : '00:00'}
				</div> */}
			</div>
		</div>
	)
}

// ===================================================================

function BrightnessTableRow({
	period,
	periods,
	onStartChange,
	onIdleBrightnessChange,
	onActiveBrightnessChange,
	i,
	disabledStart,
	disabledValues,
	className,
	splitPeriod,
	deletePeriod,
	is1212Mode
}) {
	const [tempIdleValue, setTempIdleValue] = useState(Math.round(period.idleBrightness * 100))
	const [tempActiveValue, setTempActiveValue] = useState(Math.round(period.activeBrightness * 100))

	useEffect(() => {
		if (tempIdleValue != Math.round(period.idleBrightness * 100)) {
			setTempIdleValue(Math.round(period.idleBrightness * 100))
		}

		if (tempActiveValue != Math.round(period.activeBrightness * 100)) {
			setTempActiveValue(Math.round(period.activeBrightness * 100))
		}
	}, [period.activeBrightness, period.idleBrightness])

	const fake = period.fake

	const nextFake = periods[i + 1]?.fake
	const prevFake = periods[i - 1]?.fake

	function onStartTimeChange(newDate) {
		let seconds = dateToSecondsToday(newDate)

		if (is1212Mode) {
			if (seconds < 43200) {
				seconds += 43200
			} else {
				seconds -= 43200
			}
		}

		onStartChange(seconds)
	}

	return (
		<tr className={className}>
			<td className="num-cell">
				<div>{i + 1}.</div>
			</td>
			<td>
				<TimePicker
					value={secondsTodayToDate(period.start + (is1212Mode ? 43200 : 0))}
					disabled={disabledStart}
					onChange={onStartTimeChange}
				/>
			</td>
			<td className="brightness-input idle">
				<NumericInput
					size="small"
					variant="minimal"
					value={tempIdleValue}
					disabled={disabledValues}
					onValueChange={(val) => {
						if (val < 0 || val > 100) return
						setTempIdleValue(val)
					}}
					onBlur={() => onIdleBrightnessChange(clamp(tempIdleValue, 0, 100))}
					selectAllOnFocus={true}
					onButtonClick={(newVal) => onIdleBrightnessChange(clamp(newVal, 0, 100))}
				/>
			</td>
			<td className="brightness-input motion">
				<NumericInput
					size="small"
					variant="minimal"
					value={tempActiveValue}
					disabled={disabledValues}
					onValueChange={(val) => {
						if (val < 0 || val > 100) return
						setTempActiveValue(val)
					}}
					onBlur={() => onActiveBrightnessChange(clamp(tempActiveValue, 0, 100))}
					selectAllOnFocus={true}
					onButtonClick={(newVal) => onActiveBrightnessChange(clamp(newVal, 0, 100))}
				/>
			</td>
			<td className="row-action-cell">
				<div className="row-action">
					<div className="tickerAction">
						<ButtonGroup>
							{!(nextFake && prevFake) &&
								!(i == 0 && nextFake) &&
								!(i == periods.length - 1 && prevFake) &&
								!(i == 0 && periods.length == 1) && (
									<Button
										size="small"
										variant="minimal"
										intent="danger"
										icon={<i className="fa-solid fa-minus"></i>}
										onClick={() => deletePeriod(i)}
									/>
								)}
							{!fake && (
								<Button
									size="small"
									variant="minimal"
									intent="success"
									icon={<i className="fa-solid fa-plus"></i>}
									onClick={() => splitPeriod(i)}
								/>
							)}
						</ButtonGroup>
					</div>
				</div>
			</td>
		</tr>
	)
}

// ===================================================================

function Condition({ conditionType, parentConditions, data, onRemove, onValueChange }) {
	let condition = conditionTypes.find((c) => c.type == conditionType)
	if (!condition) {
		condition = { label: conditionType, icon: 'fa-question' }
	}
	let valueEl = null
	if (conditionType == 'weekdays') {
		const value = parentConditions.weekdays
		const abriviations = 'Mon,Tue,Wed,Thu,Fri,Sat,Sun'.split(',')
		valueEl = (
			<ButtonGroup>
				{abriviations.map((abriv, i) => (
					<WeekdayConditionButton key={`cwcb${i}`} value={value} numValue={i + 1} label={abriv} onValueChange={onValueChange} />
				))}
			</ButtonGroup>
		)
	} else if (conditionType == 'date') {
		const value = parentConditions.date
		valueEl = (
			<DateInput2
				className="bp5-small"
				value={value}
				onChange={onValueChange}
				parseDate={(date) => null}
				formatDate={(date) => DateTime.fromJSDate(date).toFormat('yyyy-LL-dd')}
				highlightCurrentDay={true}
			/>
		)
	} else if (conditionType == 'daylight' || conditionType == 'nightlight') {
		const value = parentConditions[conditionType]
		valueEl = (
			<>
				<div className="lineBreak">
					<NumericInput
						size="small"
						className="smallNumeric"
						value={value.sunriseOffset}
						onValueChange={(val) => !isNaN(val) && onValueChange({ ...value, sunriseOffset: val })}
					/>
					<span className="small">{Lang.get('sunrise offset')}</span>
				</div>
				<div className="lineBreak">
					<NumericInput
						size="small"
						className="smallNumeric"
						value={value.sunsetOffset}
						onValueChange={(val) => !isNaN(val) && onValueChange({ ...value, sunsetOffset: val })}
					/>
					<span className="small">{Lang.get('sunset offset')}</span>
				</div>
			</>
		)
	} else {
		valueEl = <pre>{JSON.stringify(data, null, 2)}</pre>
	}
	return (
		<div className="Condition">
			<div className="icon">
				<i className={`fa-fw fa-solid ${condition.icon}`}></i>
			</div>
			<div className="label">{condition.label}</div>
			<div className="value">{valueEl}</div>

			<div className="removeButton">
				<Button size="small" variant="minimal" intent="danger" icon={<i className="fa-solid fa-xmark"></i>} onClick={onRemove} />
			</div>
		</div>
	)
}

// ===================================================================

function WeekdayConditionButton({ value, numValue, label, onValueChange }) {
	const isActive = value.includes(numValue)
	return (
		<Button
			size="small"
			intent={isActive ? 'primary' : undefined}
			onClick={() => {
				onValueChange(isActive ? value.filter((v) => v != numValue) : [...value, numValue])
			}}>
			{label}
		</Button>
	)
}
