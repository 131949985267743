import './index.scss'

import React from 'react'
import Page from '../../../Components/Page'
import { Button } from '@blueprintjs/core'
import API from '../../../API'
import Time from '../../../Components/Time'

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.uploadButtonRef = React.createRef()
		this.state = {
			uploadBusy: false,
			firmwares: []
		}
		this.reload()
	}

	async reload() {
		const firmwares = await API.call('Admin.Firmware.List')
		console.log({ firmwares })
		this.setState({ firmwares })
	}

	uploadNew() {
		this.setState({ uploadBusy: true })
		let fileInput = document.createElement('input')
		fileInput.type = 'file'
		fileInput.onchange = async () => {
			const fwBuffer = await fileInput.files[0].arrayBuffer()

			const comment = prompt('Upload the firmware? Leave any comments about it here:')
			if (comment === null) {
				return
			}

			const firmwareBase64 = btoa(String.fromCharCode(...new Uint8Array(fwBuffer)))
			console.log({ firmwareBase64 })

			const res = await API.call('Admin.Firmware.Upload', { firmwareBase64, comment })
			console.log({ res })

			await this.reload()
			this.setState({ uploadBusy: false })
		}
		fileInput.click()
	}

	async delete(fw) {
		if (confirm('Are you sre you want to delete this firmware?')) {
			await API.call('Admin.Firmware.Delete', { id: fw.id })
			await this.reload()
		}
	}

	render() {
		return (
			<Page title="Firmware Admin" className="AdminFirmwaresPage" icon="fa-duotone fa-file-binary">
				<Button disabled={this.state.uploadBusy} intent="primary" onClick={() => this.uploadNew()}>
					Upload New Firmware
				</Button>
				<br />
				<br />
				<table className="bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped">
					<thead>
						<tr>
							<th width="150">Uploaded</th>
							<th width="50">Hardware</th>
							<th width="50">Firmware</th>
							<th width="50">Bytes</th>
							<th>Dev</th>
							<th>Comment</th>
							<th width="50"></th>
						</tr>
					</thead>
					<tbody>
						{this.state.firmwares.map((fw) => (
							<tr>
								<td>
									<Time timestamp={fw.uploaded} />
								</td>
								<td>{fw.hardware}</td>
								<td>{fw.version}</td>
								<td>{fw.size}</td>
								<td>{fw.deployment}</td>
								<td>{fw.comment}</td>
								<td>
									<Button size="small" icon="trash" intent="danger" onClick={() => this.delete(fw)} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Page>
		)
	}
}
