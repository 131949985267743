import './App.scss'

import API from '../API'
import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Settings } from 'luxon'

import MapPage from '../Pages/Map'
import LoginPage from '../Pages/Login'
import PlaceholderPage from '../Pages/Placeholder'
import ReportsPage from '../Pages/Reports'
import SettingsPage from '../Pages/Settings'
import InspectorPage from '../Pages/Inspector'

import { colorizeProfiles, isAdmin, isInspector } from '../Util'
import AdminRFToolsPage from '../Pages/Admin/RFTools'
import AdminRFTrafficPage from '../Pages/Admin/RFTraffic'
import AdminGSMToolsPages from '../Pages/Admin/GSMTools'
import AdminFirmaresPage from '../Pages/Admin/Firmwares'
import AdminConsolePage from '../Pages/Admin/Console'

import { OverlayToaster, Position } from '@blueprintjs/core'
import Placeholder from './Placeholder'
import { Settings } from 'luxon'

export const AppToaster = OverlayToaster.createAsync({
	position: Position.TOP
})

const AppContext = createContext({
	appStatus: 'CONNECTING',
	user: null,
	account: null,
	accounts: null,
	additionalData: null,
	devices: null,
	setDevices: () => {},
	profiles: null,
	setProfiles: () => {},
	updateProfileForDevices: () => {},
	digestServerUpdate: () => {},
	events: null
})

export const useAppContext = () => useContext(AppContext)

export default function App() {
	const [appStatus, setAppStatus] = useState('CONNECTING')

	const [user, setUser] = useState(null)
	const [account, setAccount] = useState(null)
	const [accounts, setAccounts] = useState(null)
	const [additionalData, setAdditionalData] = useState(null)
	const [devices, _setDevices] = useState(null)
	const [profiles, _setProfiles] = useState(null)

	const digestServerUpdate = useCallback(
		(updateObj) => {
			if (!updateObj) return

			const keys = Object.keys(updateObj)
			if (keys.length == 0) return

			if (keys.includes('devices')) {
				const arr = updateObj.devices
				console.log(arr)
				if (!Array.isArray(arr)) {
					console.error('Server update object invalid for "devices":', arr)
					return
				}

				_setDevices((devices) => {
					return devices.map((device) => {
						const replaceData = arr.find((i) => i.targetIds?.includes(device.id))
						if (replaceData) {
							return {
								...device,
								...replaceData.data
							}
						}

						return device
					})
				})
			}
		},
		[_setDevices]
	)

	const setDevices = useCallback(
		(devices) => {
			_setDevices(devices)
		},
		[_setDevices]
	)

	const setProfiles = useCallback(
		(profiles) => {
			if (typeof profiles === 'object') {
				_setProfiles(colorizeProfiles(profiles))
			} else if (typeof profiles === 'function') {
				_setProfiles((curProfiles) => colorizeProfiles(profiles(curProfiles)))
			}
		},
		[_setProfiles]
	)

	const updateProfileForDevices = useCallback(
		(deviceIds, newProfileId) => {
			// Add new profile to devices
			_setDevices((devices) => {
				return devices.map((device) => {
					if (deviceIds.includes(device.id)) {
						return {
							...device,
							lightingProfileId: newProfileId
						}
					}

					return device
				})
			})
		},
		[_setDevices]
	)

	const [events, setEvents] = useState(null)

	useLayoutEffect(() => {
		API.addConnectionListener('root-app', async (connected) => {
			console.log('[App] connected =', connected, 'root-app')

			if (connected) {
				const sessionId = localStorage.getItem('sessionId')
				if (sessionId) {
					let res = await API.call('authorize', { sessionId })
					if (res.user) {
						console.log('User: ', res.user)

						setUser(res.user)
						setAccount(res.account)
						setAccounts(res.accounts)
						setAdditionalData(res.additionalData)

						setAppStatus('ONLINE')

						const accountData = await API.call('Account.GetData')
						console.log('accountData', accountData)

						colorizeProfiles(accountData.profiles)

						setDevices(accountData.devices)
						setProfiles(accountData.profiles)
						setEvents(accountData.events)

						Settings.defaultZone = res.account.timeZone
						return
					} else {
						localStorage.removeItem('sessionId')
					}
				}

				setUser(null)
				setAccount(null)
				setAccounts(null)
				setAdditionalData(null)

				setAppStatus('NEEDS-LOGIN')
			} else {
				setAppStatus('CONNECTING')
			}
		})

		return () => {
			API.removeApiListener('root-app')
		}
	}, [])

	return (
		<AppContext.Provider
			value={{
				appStatus,

				user,
				account,
				accounts,
				additionalData,

				devices,
				setDevices,
				profiles,
				setProfiles,
				updateProfileForDevices,
				digestServerUpdate,
				events
			}}>
			<Root appStatus={appStatus} user={user} />
		</AppContext.Provider>
	)
}

function Root({ appStatus, user }) {
	if (appStatus == 'CONNECTING' || (appStatus == 'ONLINE' && !user)) {
		return <Placeholder className="white-bg" loading label={'Connecting ...'} />
	} else if (appStatus == 'NEEDS-LOGIN') {
		return <LoginPage />
	} else if (appStatus == 'ONLINE') {
		return (
			<BrowserRouter>
				<Routes>
					<Route
						path="*"
						element={
							<PlaceholderPage
								key="404"
								title="Kļūda 404"
								message="Lapa nav atrasta!"
								icon="fa-duotone fa-triangle-exclamation"
							/>
						}
					/>

					<Route path="/" element={<MapPage />} />
					<Route path="/reports" element={<ReportsPage />} />
					<Route path="/settings" element={<SettingsPage />} />
					{isInspector() && <Route path="/inspector" element={<InspectorPage />} />}

					{isAdmin() && (
						<>
							<Route path="/admin/rf-tools" element={<AdminRFToolsPage />} />
							<Route path="/admin/rf-traffic" element={<AdminRFTrafficPage />} />
							<Route path="/admin/gsm-tools" element={<AdminGSMToolsPages />} />
							<Route path="/admin/firmwares" element={<AdminFirmaresPage />} />
							<Route path="/admin/console" element={<AdminConsolePage />} />
						</>
					)}
				</Routes>
			</BrowserRouter>
		)
	}
}
