import './index.scss'

import React, { useEffect, useMemo, useState } from 'react'
import API from '../../../../API'
import Lang from '../../../../Lang'
import DeviceChoice from '../DeviceChoice'
import { Button, EditableText, FormGroup, H1, H2, InputGroup, Spinner, Tooltip } from '@blueprintjs/core'
import ConfirmPopover from '../../../../Components/ConfirmPopover'

import ProfileV1Editor from './ProfileV1Editor'
import ProfileV2Editor from './ProfileV2Editor'
import { AppToaster, useAppContext } from '../../../../Components/App'
import StickyFooter from '../StickyFooter'

export function placeIdsOnPeriods(periods) {
	if (!periods) return

	for (const [i, p] of periods.entries()) {
		p.id = i
	}
}

// used to make block from 00-00 format to 12-12 format
// shifts forward, if overflows, bounces back
// clears extendor blocks and removes duration property
export function shifterizeBrightnessLevels(brightnessLevels) {
	if (!brightnessLevels) return

	let newLevels = structuredClone(brightnessLevels)

	newLevels.forEach((period) => {
		delete period.duration

		period.start += 43200

		if (period.start >= 86400) {
			period.start -= 86400
		}
	})

	newLevels.sort((a, b) => a.start - b.start)

	if (newLevels.length > 1) {
		// const levelsWithoutExtendors = newLevels.filter((p) => !p.extendor)
		// if (levelsWithoutExtendors.length !== 0) {
		// 	newLevels = levelsWithoutExtendors
		// } else {
		// 	newLevels = newLevels.slice(-1)
		// }
		newLevels = newLevels.filter((p) => !p.extendor)
	} else {
		delete newLevels[0].extendor
	}

	return newLevels
}

// used to make block from 12-12 format back to 00-00 format (database format)
// shifts back, if overflows, bounces forward
// adds extendor block if necessary
export function unshifterizeBrightnessLevels(brightnessLevels, fill = true) {
	if (!brightnessLevels) return

	let newLevels = structuredClone(brightnessLevels)

	newLevels.forEach((period) => {
		period.start -= 43200

		if (period.start < 0) {
			period.start += 86400
		}
	})

	newLevels.sort((a, b) => a.start - b.start)

	if (newLevels[0].start != 0) {
		console.warn('unshifterizeBlock: [0] shifted by', newLevels[0].start, ', filling...')

		if (fill) {
			const last = structuredClone(newLevels[newLevels.length - 1])
			newLevels.unshift({
				...last,
				start: 0,
				extendor: true
			})
		} else {
			newLevels[0].start = 0
		}
	}

	return newLevels
}

export default function ProfileDetails({ profile, initialDeviceIds, selectProfile, wantsDuplicate }) {
	const { profiles, setProfiles, updateProfileForDevices, digestServerUpdate } = useAppContext()

	const [isSavingProfile, setIsSavingProfile] = useState(false)

	const [editProfile, setEditProfile] = useState({ version: 1, ...profile })

	const [deviceIds, setDeviceIds] = useState(initialDeviceIds)

	useMemo(() => {
		const prfl = structuredClone(profile)

		// for v2
		if (prfl.data.version == 2) {
			// loop thru condition blocks
			prfl.data.conditionBlocks.forEach((block) => {
				// if is1212Mode, we want to shift it forward 12h
				if (block.is1212Mode) {
					block.brightnessLevels = shifterizeBrightnessLevels(block.brightnessLevels)
				}

				// depend on ids because FUCK indexes
				placeIdsOnPeriods(block.brightnessLevels)
			})
		}

		setDeviceIds(initialDeviceIds)
		setEditProfile({ version: 1, ...prfl })
	}, [profile.id])

	async function save() {
		setIsSavingProfile(true)

		const profileToSend = structuredClone(editProfile)

		if (profileToSend.data.version == 2) {
			profileToSend.data.conditionBlocks.forEach((block) => {
				if (block.is1212Mode === undefined) {
					block.is1212Mode = false
				}

				const hasFakesOnEnds =
					(block.is1212Mode && block.conditions.nightlight) || (!block.is1212Mode && block.conditions.daylight)
				const hasFakeInMiddle =
					(block.is1212Mode && block.conditions.daylight) || (!block.is1212Mode && block.conditions.nightlight)

				if (hasFakesOnEnds) {
					block.brightnessLevels = block.brightnessLevels.filter((p) => !p.fake)
					block.brightnessLevels[0].start = 0
				} else if (hasFakeInMiddle) {
					const fakeIdx = block.brightnessLevels.findIndex((p) => p.fake)

					const fakeBlock = block.brightnessLevels[fakeIdx]
					const fakeBlockDuration = block.brightnessLevels[fakeIdx + 1].start - fakeBlock.start

					block.brightnessLevels[fakeIdx + 1].start -= Math.round(fakeBlockDuration / 2)
					block.brightnessLevels = block.brightnessLevels.filter((p) => !p.fake)
				}

				// just in case, clear extendor
				block.brightnessLevels.forEach((period) => {
					delete period.extendor
				})

				// if is1212Mode then shift back 12h
				if (block.is1212Mode) {
					block.brightnessLevels = unshifterizeBrightnessLevels(block.brightnessLevels)
				}

				// set duration and remove id
				block.brightnessLevels.forEach((period, idx) => {
					const nextPeriod = block.brightnessLevels[idx + 1]
					const duration = (nextPeriod?.start ?? 86400) - period.start
					period.duration = duration
					delete period.id
				})
			})
		}

		const isCreating = profileToSend.id == 0
		// console.log('LightingProfile.CreateOrUpdate', editProfile)
		const res = await API.call('LightingProfile.CreateOrUpdate', {
			...profileToSend,
			deviceIds: deviceIds
		})
		if (res.success) {
			;(await AppToaster).show({ message: Lang.get(`Profile ${isCreating ? 'created' : 'saved'}`), intent: 'success' })

			if (isCreating) {
				setProfiles([...profiles, { ...structuredClone(profileToSend), id: res.id }])
			} else {
				setProfiles((profiles) => {
					return profiles.map((p) => {
						if (p.id == profileToSend.id) {
							return { ...structuredClone(profileToSend) }
						}

						return p
					})
				})
			}

			if (res.update) {
				digestServerUpdate({
					devices: [
						...res.update.devices,
						{
							targetIds: initialDeviceIds.filter((id) => !deviceIds.includes(id)),
							data: {
								isConfigured: false,
								lightingProfileId: null
							}
						}
					]
				})
			}
		}

		setIsSavingProfile(false)

		selectProfile(null)
	}

	async function deleteProfile() {
		if (editProfile.id != 0) {
			await API.call('LightingProfile.Delete', { id: editProfile.id })
			;(await AppToaster).show({ message: Lang.get('Profile deleted'), intent: 'success' })

			updateProfileForDevices(initialDeviceIds, null)
			setProfiles(profiles.filter((p) => p.id != editProfile.id))
			selectProfile(null)
		}
	}

	return (
		<div className="ProfileDetails">
			{editProfile.data.version == 2 && (
				<div className="duplicateButton">
					<Tooltip content={Lang.get('Duplicate Profile')} position="bottom">
						<Button icon="duplicate" variant="minimal" onClick={wantsDuplicate} />
					</Tooltip>
				</div>
			)}

			<div className="closeButton">
				<Button icon="cross" variant="minimal" onClick={() => selectProfile(null)} />
			</div>

			<div className="content">
				<FormGroup label={Lang.get('Profile Title')}>
					<InputGroup value={editProfile.title} onChange={(e) => setEditProfile((ep) => ({ ...ep, title: e.target.value }))} />
				</FormGroup>

				{/* <H2>
					<EditableText
						placeholder="Edit title..."
						value={editProfile.title}
						onChange={(e) => setEditProfile((ep) => ({ ...ep, title: e.target.value }))}
						style={{ marginBottom: '2rem' }}
					/>
				</H2> */}

				{editProfile.data.version == 1 && (
					<ProfileV1Editor
						profileData={editProfile.data}
						onChange={(vals) => setEditProfile((ep) => ({ ...ep, data: { ...ep.data, ...vals } }))}
					/>
				)}

				{editProfile.data.version == 2 && (
					<ProfileV2Editor
						lightingProfileId={profile.id}
						profileAdditionalData={editProfile.additionalData}
						profileData={editProfile.data}
						onChange={(vals) => setEditProfile((ep) => ({ ...ep, data: { ...ep.data, ...vals } }))}
					/>
				)}

				<div className="heading">Devices</div>
				<DeviceChoice deviceIds={deviceIds} onChange={(newDeviceIds) => setDeviceIds(newDeviceIds)} />
			</div>

			<StickyFooter>
				{editProfile.id != 0 && (
					<ConfirmPopover message={Lang.get('Are you sure?')} confirmTitle={Lang.get('Confirm')} onConfirm={deleteProfile}>
						<Button text={Lang.get('Delete Profile')} icon="trash" intent="danger" />
					</ConfirmPopover>
				)}
				<div />
				<ConfirmPopover
					message={Lang.get('Are you sure?')}
					confirmTitle={Lang.get('Confirm')}
					onConfirm={save}
					confirmIntent="primary">
					<Button
						text={editProfile.id == 0 ? Lang.get('Create Profile') : Lang.get('Save')}
						icon={isSavingProfile ? <Spinner className="spinner-icon white" /> : 'floppy-disk'}
						intent="primary"
						disabled={isSavingProfile}
					/>
				</ConfirmPopover>
			</StickyFooter>
		</div>
	)
}
