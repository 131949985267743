import { useMemo, useState } from 'react'
import ConfirmPopover from '../../../../Components/ConfirmPopover'
import Lang from '../../../../Lang'
import StickyFooter from '../StickyFooter'
import './index.scss'

import { Button, Callout, Spinner, TextArea } from '@blueprintjs/core'
import { AppToaster, useAppContext } from '../../../../Components/App'

export default function AddDeviceBlock({ wantsStop }) {
	const { setDevices } = useAppContext()

	const [value, setValue] = useState('')

	const deviceIds = useMemo(() => {
		const ids = value.split('\n').map((id) => id.trim())
		return ids.filter((id) => id !== '' && !isNaN(id)).map(Number)
	}, [value])

	console.log(deviceIds)

	const [isBusy, setIsBusy] = useState(false)

	async function onConfirm() {
		setIsBusy(true)

		const res = await API.call('Admin.Account.AddDevices', {
			deviceIds
		})

		if (res?.addedDevices.length > 0) {
			setDevices((devices) => [...devices, ...res.addedDevices])
		}

		// if (res?.addedDevices.length !== deviceIds.length) {
		// 	const ignored = deviceIds.filter((id) => !res.addedDevices.map((d) => d.id).includes(id))
		// 	console.log('ignored', ignored)
		// 	;(await AppToaster).show({ message: Lang.get('Some devices were ignored'), intent: 'warning' })
		// }

		// always success because if any devices are ignored then still true
		;(await AppToaster).show({ message: Lang.get('Devices added'), intent: 'success' })
		wantsStop()
	}

	return (
		<div className="add-device-block">
			<div className="close-btn">
				<Button icon="cross" variant="minimal" intent="danger" onClick={wantsStop} />
			</div>

			<div className="title">
				<span>{Lang.get('Add Devices')}</span>
			</div>

			<Callout intent="primary">
				{Lang.get('Enter the device ID (for example 7396), each on its own line, in the following text field.')}
			</Callout>

			<Callout intent="warning">{Lang.get('IDs that are not associated with a real device will be ignored.')}</Callout>

			<div className="device-count-hint">
				{Lang.get('Devices in list')}: {deviceIds.length}
			</div>
			<TextArea
				autoResize={true}
				className="id-input"
				value={value}
				onChange={(e) => setValue(e.target.value)}
				placeholder={`7396\n7474\n6369`}
			/>

			<StickyFooter className="footer">
				<ConfirmPopover
					message={Lang.get('Are you sure?')}
					confirmTitle={Lang.get('Confirm')}
					onConfirm={onConfirm}
					confirmIntent="primary">
					<Button
						text={Lang.get('Add')}
						icon={isBusy ? <Spinner className="spinner-icon white" /> : 'floppy-disk'}
						intent="primary"
						disabled={isBusy}
						style={{ marginLeft: 'auto' }}
					/>
				</ConfirmPopover>
			</StickyFooter>
		</div>
	)
}
