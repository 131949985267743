/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const NotificationsAdd: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="notifications-add" ref={ref}  {...props}>
            <path
                d={isLarge ? "M240.0540000000001 352.5808C240.018 351.725 240 350.8646 240 350C206.862 350 180 323.137 180 290C180 256.863 206.862 230 240 230C240 196.862 266.862 170 300 170C307.012 170 313.7440000000001 171.204 320 173.414V120C320 109 329 100 340 100C351 100 360 91 360 80C360 69 351 60 340 60H60C49 60 40 69 40 80C40 91 49 100 60 100C71 100 80 109 80 120V240C80 292.2 113.4 336.2 160 352.6V360C160 382 178 400 200 400C222 400 240 382 240 360V352.6L240.0540000000001 352.5808zM240 40C240 18 222 0 200 0C178 0 160 18 160 40H240zM280 350C280 361.0456 288.954 370 300 370C311.046 370 320 361.0456 320 350V310H360C371.046 310 380 301.0456 380 290C380 278.9544 371.046 270 360 270H320V230C320 218.9544 311.046 210 300 210C288.954 210 280 218.9544 280 230V270H240C228.954 270 220 278.9544 220 290C220 301.0456 228.954 310 240 310H280V350z" : "M179.6 298L179.7382 297.9728L179.5706 297.4292C154.9338 289.962 137 267.0754 137 240C137 212.9246 154.9338 190.038 179.5706 182.5708C187.038 157.9338 209.924 140 237 140C245.148 140 252.916 141.6242 260 144.5664V120C260 109 269 100 280 100C291 100 300 91 300 80C300 69 291 60 280 60H40C29 60 20 69 20 80C20 91 29 100 40 100C51 100 60 109 60 120V200C60 248.6 94.6 289 140.4 298C140.4 298.4 140.3 298.7 140.2 299C140.1 299.3 140 299.6 140 300C140 311 149 320 160 320C171 320 180 311 180 300C180 299.6 179.9 299.3 179.8 299C179.7 298.7 179.6 298.4 179.6 298zM160 0C182 0 200 18 200 40H120C120 18 138 0 160 0zM280 260H260V280C260 291.0456 251.046 300 240 300C228.954 300 220 291.0456 220 280V260H200C188.9544 260 180 251.0456 180 240C180 228.9544 188.9544 220 200 220H220V200C220 188.9544 228.954 180 240 180C251.046 180 260 188.9544 260 200V220H280C291.046 220 300 228.9544 300 240C300 251.0456 291.046 260 280 260z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
NotificationsAdd.defaultProps = {
    size: IconSize.STANDARD,
};
NotificationsAdd.displayName = `Blueprint5.Icon.NotificationsAdd`;
export default NotificationsAdd;
