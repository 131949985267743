import './index.scss'

import { useCallback, useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import API from '../../../../../../API'
import { Button } from '@blueprintjs/core'
import ChartGroup from './Components/ChartGroup'
import StatsChart from './Components/StatsChart'
import { baseKeyDetails, d4iKeyDetails, DeviceDetailsBlock, keyDetails, modbusKeyDetails } from '../..'
import Lang from '../../../../../../Lang'
import Placeholder from '../../../../../../Components/Placeholder'

const enabledGraphKeys = [
	'energyConsumption',
	'trafficIntensity',
	'logCount',
	'savedEnergy',

	'activePower',
	'lampShortAddress',
	'currentDimLevelFromSchedule',
	'totalActiveEnergy',
	'driverOnTime',
	'mainsVoltage',
	'mainsCurrent',
	'powerFactor',
	'driverFailureFlags',
	'driverTemperature',
	'ledOnTime',
	'ledFailureFlags',
	'ledTemperature',
	'ledVoltage',
	'ledCurrent',

	'phaseNumber_mb',
	'voltage_mb',
	'current_mb',
	'activePower_mb',
	'reactivePower_mb',
	'apparentPower_mb',
	'frequency_mb',
	'totalActiveEnergy_mb'
]

export default function HistoryTabPanel({ device }) {
	const [curDate, setCurDate] = useState(DateTime.now().startOf('day'))

	const [expectedKeys, setExpectedKeys] = useState([])
	const [busy, setBusy] = useState(false)
	const [data, setData] = useState(null)

	const hydrateChartData = useCallback(async (dateStr) => {
		setBusy(true)

		const res = await API.call('Statistics.Full', {
			deviceIds: [device.id],
			step: 'hour',
			from: dateStr,
			to: dateStr
		})

		const keysToExpect = [...Object.keys(baseKeyDetails)]

		keysToExpect.push(...Object.keys(d4iKeyDetails))

		keysToExpect.push(...Object.keys(modbusKeyDetails))

		setExpectedKeys(keysToExpect.filter((key) => enabledGraphKeys.includes(key)))
		setData(res)

		setBusy(false)
	}, [])

	useEffect(() => {
		setBusy(true)
		hydrateChartData(curDate.toFormat('yyyy-LL-dd'))

		// return () => setData(null)
	}, [curDate])

	function wantsViewInReports() {
		window.open(`/reports?devices=%5B${device.id}%5D`, '_blank')
	}

	return (
		<div className="device-history-tab">
			<Button icon="vertical-bar-chart-asc" text={Lang.get('View in Reports')} onClick={wantsViewInReports} />

			<ChartGroup dateValue={curDate} onDateChange={(newDate) => !busy && setCurDate(newDate)}>
				{busy && expectedKeys.length === 0 && <Placeholder loading />}

				{expectedKeys.map((key) => {
					const arr = Object.values(data ?? { _: {} }).map((d) => d[key])
					if (arr.every((v) => v === undefined)) return null

					const getTitle = () => {
						const keyData = keyDetails[key]
						if (!keyData) return key

						const unit = keyData.unit

						return `${keyData.label} ${unit ? `(${unit})` : ''}`.trim()
					}

					return (
						<DeviceDetailsBlock title={getTitle()} className={`chartBlock `} key={`stats-chart-${key}`}>
							<StatsChart data={arr} displayKey={key} loading={busy} />
						</DeviceDetailsBlock>
					)
				})}
			</ChartGroup>
		</div>
	)
}
