/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const SelectionBoxAdd: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="selection-box-add" ref={ref}  {...props}>
            <path
                d={isLarge ? "M40 400H100V360H40L40 300H0V360V380C0 391.0457 8.9543 400 20 400H40zM360 40V100H400V40V20C400 8.954 391.046 0 380 0H360H300V40H360zM0 100V40V20C0 8.954 8.9543 0 20 0H40H100V40H40L40 100H0zM360 400H300V360H360V300H400V360V380C400 391.0457 391.046 400 380 400H360zM240 400H160V360H240V400zM0 240V160H40L40 240H0zM180 280C180 291.0456 188.9544 300 200 300C211.046 300 220 291.0456 220 280V220H280C291.046 220 300 211.0456 300 200C300 188.954 291.046 180 280 180H220V120C220 108.954 211.046 100 200 100C188.9544 100 180 108.954 180 120V180H120C108.9544 180 100 188.954 100 200C100 211.0458 108.9544 220 120 220H180V280zM400 160H360V240H400V160zM160 0V40H240V0H160z" : "M120 280L200 280V320H120V280zM0 120V200H40V120H0zM20 320H40H80V280L40 280V240H0V280V300C0 311.0457 8.9543 320 20 320zM0 40V20C0 8.954 8.9543 0 20 0H40H80V40H40L40 80H0V40zM200 40V0H120V40H200zM320 200H280V120H320V200zM300 0H280H240V40H280V80H320V40V20C320 8.954 311.046 0 300 0zM320 280V300C320 311.0457 311.046 320 300 320H280H240V280H280V240H320V280zM140 220C140 231.0456 148.9544 240 160 240C171.0456 240 180 231.0456 180 220V180H220C231.046 180 240 171.0456 240 160C240 148.9544 231.046 140 220 140H180V100C180 88.954 171.0456 80 160 80C148.9544 80 140 88.954 140 100V140H100C88.9544 140 80 148.9544 80 160C80 171.0458 88.9544 180 100 180H140V220z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
SelectionBoxAdd.defaultProps = {
    size: IconSize.STANDARD,
};
SelectionBoxAdd.displayName = `Blueprint5.Icon.SelectionBoxAdd`;
export default SelectionBoxAdd;
