import './index.scss'

import React, { useEffect, useMemo, useState } from 'react'
import Lang from '../../../../../Lang'
import { getSunriseSunsetTimestamps, pick } from '../../../../../Util'
import ConditionBlock from './ConditionBlock'
import { Button, Callout, FormGroup, Slider } from '@blueprintjs/core'
import { DateTime } from 'luxon'
import { placeIdsOnPeriods, shifterizeBrightnessLevels } from '..'
import { useAppContext } from '../../../../../Components/App'

// ===================================================================

export default function ProfileV2Editor({ lightingProfileId, profileData, profileAdditionalData, onChange }) {
	const { additionalData } = useAppContext()

	function conditionBlockAction(index, action, data) {
		const cbs = structuredClone(profileData.conditionBlocks)

		switch (action) {
			case 'add-block': {
				cbs.splice(index == -1 ? 0 : index + 1, 0, structuredClone(conditionBlockTemplate))
				break
			}

			case 'set-value': {
				cbs[index] = { ...cbs[index], ...data }
				break
			}

			case 'delete': {
				cbs.splice(index, 1)
				break
			}

			case 'duplicate': {
				cbs.splice(index, 0, structuredClone(cbs[index]))
				break
			}

			case 'move-up': {
				if (index > 0) {
					const tmp = cbs[index]
					cbs[index] = cbs[index - 1]
					cbs[index - 1] = tmp
				}
				break
			}

			case 'move-down': {
				if (index < cbs.length - 1) {
					const tmp = cbs[index]
					cbs[index] = cbs[index + 1]
					cbs[index + 1] = tmp
				}
				break
			}

			default: {
				console.error('Unknown action', action)
				break
			}
		}

		onChange({ conditionBlocks: cbs })
	}

	const sunriseSunsetTimes = useMemo(() => {
		const profileCenter = profileAdditionalData?.center

		const ret = profileCenter
			? getSunriseSunsetTimestamps(profileCenter.latitude, profileCenter.longitude, new Date())
			: {
					sunrise: additionalData.defaultSunriseTimestamp,
					sunset: additionalData.defaultSunsetTimestamp
			  }

		const secondsNow = DateTime.now().startOf('day').toSeconds()

		return {
			sunrise: ret.sunrise - secondsNow,
			sunset: ret.sunset - secondsNow
		}
	}, [profileData])

	return (
		<div className="ProfileV2Editor">
			<FormGroup label={Lang.get('Brightness change speed (DALI)')}>
				<Slider max={7} value={profileData.daliSpeed} onChange={(val) => onChange({ daliSpeed: val })} />
			</FormGroup>

			<FormGroup label={Lang.get('Motion time')} labelInfo={'(' + Lang.get('seconds') + ')'}>
				<Slider max={600} value={profileData.motionTime} onChange={(val) => onChange({ motionTime: val })} labelStepSize={60} />
			</FormGroup>

			<br />
			<br />

			<Callout intent="primary">{Lang.get('The first schedule for which all conditions are true will be activated')}</Callout>

			<br />
			<br />

			<div className="conditionBlocks">
				<Button
					icon="add"
					intent="success"
					variant="minimal"
					text={Lang.get('Add Schedule')}
					onClick={() => conditionBlockAction(-1, 'add-block')}
				/>
				{profileData.conditionBlocks?.map((conditionBlock, index) => {
					return (
						<React.Fragment key={`pv2ecb${index}`}>
							<ConditionBlock
								lightingProfileId={lightingProfileId ?? 0}
								blockData={conditionBlock}
								blockAction={(action, data) => conditionBlockAction(index, action, data)}
								sunriseSunsetTimes={sunriseSunsetTimes}
							/>
							<Button
								icon="add"
								intent="success"
								variant="minimal"
								text={Lang.get('Add Schedule')}
								onClick={() => conditionBlockAction(index, 'add-block')}
							/>
						</React.Fragment>
					)
				})}
			</div>

			<br />
			<br />
		</div>
	)
}

// ===================================================================

export const conditionBlockTemplate = {
	active: true,
	comment: '',
	conditions: {},
	is1212Mode: false,
	brightnessLevels: [
		{
			id: 0,
			start: 0,
			duration: 21600,
			idleBrightness: 0.1,
			activeBrightness: 0.5
		},
		{
			id: 1,
			start: 21600,
			duration: 21600,
			idleBrightness: 0.2,
			activeBrightness: 0.6
		},
		{
			id: 2,
			start: 43200,
			duration: 21600,
			idleBrightness: 0.3,
			activeBrightness: 0.7
		},
		{
			id: 3,
			start: 64800,
			duration: 21600,
			idleBrightness: 0.4,
			activeBrightness: 0.8
		}
	]
}

// ===================================================================

export const profileTemplate = {
	conditionBlocks: [
		// { ...conditionBlockTemplate, comment: Lang.get('September 1st'), active: false, conditions: { date: '2021-01-01' } },
		{ ...conditionBlockTemplate, comment: Lang.get('Workdays'), conditions: { weekdays: [1, 2, 3, 4, 5] } },
		{ ...conditionBlockTemplate, comment: Lang.get('Other Days') }
	]
}

// ===================================================================
