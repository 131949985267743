import './DevicePicker.scss'

import { useCallback, useEffect, useMemo, useState } from 'react'
import Lang from '../Lang'
import Placeholder from './Placeholder'
import { Classes } from '@blueprintjs/popover2'
import { Button, Checkbox } from '@blueprintjs/core'
import { useAppContext } from './App'
import ProfileIcon from './CustomIcons/ProfileIcon'
import IconTextBox from './IconTextBox'
import { SearchInput } from '../Pages/Map/Components/Tabs/Devices'

export default function DevicePicker({ selectedDeviceIds: initialSelectedDeviceIds, onChange, hideDeviceIds = [] }) {
	const { profiles, devices } = useAppContext()

	const [selectedDeviceIds, setSelectedDeviceIds] = useState(initialSelectedDeviceIds)
	const [searchingFor, setSearchingFor] = useState('')

	function confirm() {
		onChange(selectedDeviceIds)
	}

	const toggleDeviceId = useCallback(
		(deviceId) => {
			setSelectedDeviceIds((prevIds) => {
				const index = prevIds.indexOf(deviceId)
				if (index === -1) {
					return [...prevIds, deviceId]
				}
				const newIds = [...prevIds]
				newIds.splice(index, 1)
				return newIds
			})
		},
		[setSelectedDeviceIds]
	)

	const deviceIdsWithNoProfile = useMemo(() => {
		return devices
			.filter((d) => !d.lightingProfileId && d.id.toString().includes(searchingFor))
			.map((d) => d.id)
			.sort((a, b) => a - b)
	}, [devices, searchingFor])

	return (
		<div className="DevicePicker">
			{!profiles && <Placeholder loading />}
			{profiles && (
				<>
					<div className="device-picker-content">
						<SearchInput value={searchingFor} onChange={(e) => setSearchingFor(e.target.value)} />

						{profiles.map((p) => {
							const devicesIdsForProfile = devices
								.filter(
									(d) => d.lightingProfileId === p.id && !hideDeviceIds.includes(d.id) && d.id.toString().includes(searchingFor)
								)
								.map((d) => d.id)

							if (devicesIdsForProfile.length === 0) return null

							devicesIdsForProfile.sort((a, b) => a - b)

							return (
								<div className="LightProfile" key={p.id}>
									<IconTextBox iconElement={<ProfileIcon profileColor={p.color ?? 'gray'} size={1.25} />}>
										<div className="profileTitle">{p.title}</div>
									</IconTextBox>

									<div className="deviceList">
										{devicesIdsForProfile.map((id) => (
											<div className="Device" key={id}>
												<Checkbox checked={selectedDeviceIds.indexOf(id) !== -1} onChange={() => toggleDeviceId(id)}>
													<i className="fa-fw fa-duotone fa-router" />
													<div className="deviceId">{id}</div>
												</Checkbox>
											</div>
										))}
									</div>
								</div>
							)
						})}

						{deviceIdsWithNoProfile.length > 0 && (
							<div className="LightProfile">
								<IconTextBox iconElement={<ProfileIcon profileColor="gray" size={1.25} />}>
									<div className="profileTitle">{Lang.get('Other Devices')}</div>
								</IconTextBox>
								<div className="deviceList">
									{deviceIdsWithNoProfile
										.filter((did) => !hideDeviceIds.includes(did))
										.map((id) => (
											<div className="Device" key={id}>
												<Checkbox checked={selectedDeviceIds.indexOf(id) !== -1} onChange={() => toggleDeviceId(id)}>
													<i className="fa-fw fa-duotone fa-router" />
													<div className="deviceId">{id}</div>
												</Checkbox>
											</div>
										))}
								</div>
							</div>
						)}
					</div>
					<div className="buttons">
						<Button
							text={Lang.get('Confirm')}
							icon="tick"
							intent="success"
							onClick={confirm}
							className={Classes.POPOVER2_DISMISS}
						/>
					</div>
				</>
			)}
		</div>
	)
}
