import { Menu, MenuItem } from '@blueprintjs/core'
import API from '../API'
import { Popover2 } from '@blueprintjs/popover2'
import { useAppContext } from './App'

export default function AccountSelector({ hideActive, onSelect }) {
	const { account: activeAccount, accounts } = useAppContext()

	const menu = (
		<Menu className="scrollable">
			{accounts?.map((account) => (
				<MenuItem
					icon="map-marker"
					text={account.name}
					key={`acc-${account.name}`}
					intent={account.name == activeAccount.name ? 'primary' : 'none'}
					onClick={() => {
						API.call('User.SetActiveAccount', { id: account.id }).then((r) => {
							if (onSelect) onSelect()
							location.reload()
						})
					}}
				/>
			))}
		</Menu>
	)

	return (
		<Popover2 content={menu} position="bottom">
			<div className="switchable">
				<i className="fa-fw fa-solid fa-location-dot" />
				{!hideActive && activeAccount?.name}
				<i className="switcher fa-fw fa-solid fa-caret-down" />
			</div>
		</Popover2>
	)
}
