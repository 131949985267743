import './ConfirmPopover.scss'

import React from 'react'
import Lang from '../Lang'
import { Button } from '@blueprintjs/core'
import { Classes, Popover2 } from '@blueprintjs/popover2'

export default function ConfirmPopover({ onConfirm, message, confirmTitle, children, confirmIntent = 'danger' }) {
	const confirm = () => {
		if (onConfirm) {
			onConfirm()
		}
	}

	const displayMessage = message || Lang.get('Are you sure?')
	const displayConfirmTitle = confirmTitle || Lang.get('Confirm')

	return (
		<Popover2
			content={
				<div className="ConfirmPopover">
					<div className="message">{displayMessage}</div>
					<div className="buttons">
						<Button text={Lang.get('Cancel')} className={Classes.POPOVER2_DISMISS} />
						<Button text={displayConfirmTitle} intent={confirmIntent} className={Classes.POPOVER2_DISMISS} onClick={confirm} />
					</div>
				</div>
			}>
			{children}
		</Popover2>
	)
}
