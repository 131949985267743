import './index.scss'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import API from '../../API'
import Lang from '../../Lang'
import ILMap from '../../Components/ILMap'
import Page from '../../Components/Page'
import DeviceDetails from './Components/DeviceDetails'
import Placeholder from '../../Components/Placeholder'
import ProfileDetails from './Components/ProfileDetails'
import { Menu, MenuItem, MenuDivider, ContextMenuPopover, Tabs, Tab } from '@blueprintjs/core'

import { profileTemplate } from './Components/ProfileDetails/ProfileV2Editor'
import { colorizeProfiles, getMapBoundsForDevices } from '../../Util'
import { useMemo } from 'react'
import DevicesTab from './Components/Tabs/Devices'
import EventsTab from './Components/Tabs/Events'
import { useQueryParamsState } from '../../Hooks/useQueryParamsState'
import { useAppContext } from '../../Components/App'
import AddDeviceBlock from './Components/AddDeviceBlock'

export default function MapPage() {
	const { devices, setDevices, profiles, events, digestServerUpdate } = useAppContext()

	const [contextMenuDeviceId, setContextMenuDeviceId] = useState(null)
	const [contextMenuPosition, setContextMenuPosition] = useState({ left: 0, top: 0 })
	const [contextMenuOpen, setContextMenuOpen] = useState(false)

	const [urlTargetDevice, setUrlTargetDevice] = useQueryParamsState('target', '')

	const [isAddingDevice, setIsAddingDevice] = useState(false)
	const [selectedDevice, setSelectedDevice] = useState(null)
	const [selectedProfile, setSelectedProfile] = useState(null)
	const [activeEventData, setActiveEventData] = useState({
		count: 0,
		hasErrors: false
	})

	const mapRef = useRef()

	async function setDeviceProfile(dev, newProfile) {
		// Ignore if profiles isn't changing
		if (dev.lightingProfileId == newProfile.id) return

		const res = await API.call('LightingProfile.AddDevice', { deviceId: dev.id, profileId: newProfile.id })
		if (!res || !res.success) {
			console.error('Failed to add device to profile', res)
			return
		}

		if (res.update) {
			digestServerUpdate(res.update)
		}
	}

	async function setSegmentChildren(dev, childrenIds) {
		if (dev.mode != 2) return

		const res = await API.call('Device.SetSegmentChildren', { segmentId: dev.id, childrenIds })
		if (!res || !res.success) {
			console.error('Failed to update device segment children', res)
			return
		}

		setDevices((devices) => {
			return devices.map((d) => {
				if (childrenIds.includes(d.id)) {
					return { ...d, parentSegmentId: dev.id }
				} else if (d.parentSegmentId == dev.id) {
					return { ...d, parentSegmentId: null }
				}

				return d
			})
		})

		if (res.update) {
			digestServerUpdate(res.update)
		}

		if (selectedDevice && childrenIds.includes(selectedDevice.id)) {
			setSelectedDevice({ ...selectedDevice, parentSegmentId: dev.id })
		}
	}

	async function setDeviceSegment(dev, parentSegmentId) {
		if (dev.parentSegmentId == parentSegmentId) return

		const res = await API.call('Device.Update', { id: dev.id, parentSegmentId })
		if (!res || !res.success) {
			console.error('Failed to update device segment', res)
			return
		}

		if (res.update) {
			digestServerUpdate(res.update)
		}

		if (selectedDevice && selectedDevice.id == dev.id) {
			setSelectedDevice({ ...selectedDevice, parentSegmentId })
		}
	}

	const contextMenuContent = useMemo(() => {
		if (!devices) return null

		const contextMenuDevice = devices.find((d) => d.id == contextMenuDeviceId)
		if (!contextMenuDevice) return null

		const neighbourValue = selectedDevice?.neighbours.find((n) => n.id == contextMenuDevice.id)

		const isSelectedSegment = selectedDevice?.mode === '2'
		const isContextSegment = contextMenuDevice?.mode === '2'

		const childToSegment = isSelectedSegment && !isContextSegment

		const menuItems = []

		if (
			!isContextSegment &&
			!isSelectedSegment &&
			selectedDevice &&
			contextMenuDevice &&
			selectedDevice.id != contextMenuDevice.id
		) {
			menuItems.push(
				neighbourValue ? (
					<MenuItem
						icon="remove"
						text={Lang.get('Neighbour to %d', selectedDevice.id)}
						onClick={() => setNeighbours(selectedDevice, [{ ...contextMenuDevice, multiplier: 0 }])}
					/>
				) : (
					<MenuItem
						icon="add"
						text={Lang.get('Neighbour to %d', selectedDevice.id)}
						onClick={() => setNeighbours(selectedDevice, [{ ...contextMenuDevice, multiplier: 1 }])}
					/>
				)
			)
		}

		if (childToSegment && !contextMenuDevice.parentSegmentId) {
			menuItems.push(
				<MenuItem
					icon="add"
					text={Lang.get('Segment %d', selectedDevice.id)}
					onClick={() => setDeviceSegment(contextMenuDevice, selectedDevice.id)}
				/>
			)
		}

		if (contextMenuDevice.parentSegmentId) {
			menuItems.push(
				<MenuItem
					icon="remove"
					text={Lang.get('Segment %d', contextMenuDevice.parentSegmentId)}
					onClick={() => setDeviceSegment(contextMenuDevice, null)}
				/>
			)
		}

		return (
			<Menu>
				<MenuItem active={true} text={Lang.get('Device') + ' ' + contextMenuDevice.id} shouldDismissPopover={false} />

				{menuItems.length > 0 && <MenuDivider />}

				{...menuItems}

				<MenuDivider />

				{profiles?.length > 0 &&
					profiles
						.filter((p) => !p.disabled)
						.map((p) => {
							const selected = contextMenuDevice?.lightingProfileId == p.id

							return (
								<MenuItem
									shouldDismissPopover={false}
									key={`cm-p-${p.id}`}
									icon={selected ? 'tick' : 'blank'}
									text={p.title}
									onClick={() => setDeviceProfile(contextMenuDevice, p)}
								/>
							)
						})}
			</Menu>
		)
	}, [contextMenuDeviceId, JSON.stringify(profiles), JSON.stringify(devices), selectedDevice])

	async function setNeighbours(device, neighbours) {
		const updatedNeighbours = device.neighbours.filter(
			(n) => !neighbours.some((neigh) => neigh.id == n.id && neigh.multiplier == 0)
		)

		neighbours.forEach((neighbour) => {
			if (neighbour.multiplier > 0) {
				updatedNeighbours.push({ id: neighbour.id, multiplier: neighbour.multiplier })
			}
		})

		setDevices((devices) => {
			return devices.map((dev) => {
				if (dev.id == device.id) {
					const newDevice = {
						...dev,
						neighbours: updatedNeighbours
					}

					device = newDevice
					setSelectedDevice(newDevice)
					selectDevice(newDevice)
					return newDevice
				}
				return dev
			})
		})

		let newNeighbours = updatedNeighbours.map((n) => ({ id: n.id, multiplier: n.multiplier }))

		await API.call('Device.SetNeighbours', { deviceId: device.id, neighbours: newNeighbours })
	}

	function selectDevice(device) {
		setSelectedDevice(device)

		if (device) {
			setIsAddingDevice(false)
			setSelectedProfile(null)
		}
	}

	function selectProfile(profile) {
		setSelectedProfile(profile)

		if (profile) {
			setIsAddingDevice(false)
			selectDevice(null)
		}
	}

	function startAddingDevice() {
		setIsAddingDevice(true)

		if (selectedDevice) {
			selectDevice(null)
		}

		if (selectedProfile) {
			selectProfile(null)
		}
	}

	function onMarkerContextMenu(e, device) {
		setContextMenuPosition({ left: e.clientX, top: e.clientY })
		setContextMenuOpen(true)
		setContextMenuDeviceId(device.id)
	}

	function onMarkerClick(device) {
		selectDevice(device)
	}

	function wantsNewProfile(args) {
		let newProfile = {
			id: 0,
			title: Lang.get('New Profile'),
			data: {
				daliSpeed: 1,
				motionTime: 60,
				deviceIds: []
			}
		}

		if (args.version == 1) {
			newProfile = {
				...newProfile,
				data: {
					...newProfile.data,
					version: 1,
					sunriseOffset: 0,
					sunsetOffset: 0,
					dayBrightness: 0,
					nightBrightness: 70,
					nightBrightnessMotion: 100,
					nightBrightnessN1: 90,
					nightBrightnessN2: 80,
					nightBrightnessN3: 0,
					nightBrightnessN4: 0,
					nightBrightnessN5: 0
				}
			}
		} else if (args.version == 2) {
			newProfile = {
				...newProfile,
				title: Lang.get('New Profile'),
				data: {
					version: 2,
					...JSON.parse(JSON.stringify(profileTemplate)),
					...(args.sourceConditionBlocks ? { conditionBlocks: args.sourceConditionBlocks } : {})
				}
			}
		}

		selectProfile(newProfile)
	}

	const [selectedTabId, setSelectedTabId] = useState('devices')

	function onReady(zoom = true) {
		mapRef.current?.resize()

		setActiveEventData({
			count: events.filter((e) => e.isActive).length,
			hasErrors: events.some((e) => e.level === 'ERROR')
		})

		if (devices.length > 0 && zoom) {
			const boundsForDevices = getMapBoundsForDevices(devices)
			mapRef.current?.fitBounds(boundsForDevices, { padding: 50, duration: 250 })
		}

		if (urlTargetDevice) {
			const targetDevice = devices.find((d) => d.id == urlTargetDevice)
			if (targetDevice) {
				selectDevice(targetDevice)
				setUrlTargetDevice('')
			}
		}
	}

	const didInit = useRef(false)
	useEffect(() => {
		if (devices !== null && profiles !== null && events !== null && !didInit.current) {
			onReady()
			didInit.current = true
		}
	}, [devices, profiles, events])

	// because layout shift
	useEffect(() => mapRef.current?.resize(), [selectedDevice, selectedProfile, isAddingDevice])
	useMemo(() => mapRef.current?.resize(), [selectedDevice, selectedProfile, isAddingDevice])

	return (
		<Page title={Lang.get('Devices')} icon="fa-duotone fa-router" className={'MapPage'} noPadding>
			<Placeholder
				overlay
				overlayVisible={devices === null || profiles === null || events === null}
				loading
				label={Lang.get('Loading') + ' ...'}
			/>

			<div className={`container sideBar open`}>
				<Tabs className="sidebarTabs" onChange={(tab) => setSelectedTabId(tab)} selectedTabId={selectedTabId}>
					<Tab
						id="devices"
						title={Lang.get('Devices')}
						panel={
							<DevicesTab
								wantsNewProfile={wantsNewProfile}
								wantsNewDevice={startAddingDevice}
								devices={devices}
								profiles={profiles}
								selectedProfile={selectedProfile}
								selectedDevice={selectedDevice}
								selectProfile={selectProfile}
								selectDevice={selectDevice}
							/>
						}
					/>
					<Tab
						id="events"
						title={Lang.get('Events')}
						panel={<EventsTab events={events} devices={devices} selectedDevice={selectedDevice} selectDevice={selectDevice} />}
						{...(activeEventData.count > 0
							? {
									className: `has-active-events ${activeEventData.hasErrors ? 'has-errors' : ''}`,
									tagContent: activeEventData.count
							  }
							: {})}
						tagProps={{
							intent: activeEventData.count > 0 ? (activeEventData.hasErrors ? 'danger' : 'warning') : 'none',
							style: {
								textAlign: 'center'
							}
						}}
					/>
				</Tabs>
			</div>

			<div className={`container fakeSideBar open`}></div>

			{selectedDevice && (
				<DeviceDetails
					device={selectedDevice}
					view={{
						selectDevice,
						setNeighbours,
						setSegmentChildren,
						setDevice: (dev) => setDevices(devices.map((d) => (d.id == dev.id ? dev : d)))
					}}
				/>
			)}

			{selectedProfile && (
				<ProfileDetails
					profile={selectedProfile}
					initialDeviceIds={devices.filter((d) => d.lightingProfileId == selectedProfile.id).map((d) => d.id)}
					selectProfile={selectProfile}
					wantsDuplicate={() => wantsNewProfile({ version: 2, sourceConditionBlocks: selectedProfile.conditionBlocks })}
				/>
			)}

			{isAddingDevice && <AddDeviceBlock wantsStop={() => setIsAddingDevice(false)} />}

			<div className="MapContainer">
				<ContextMenuPopover
					isOpen={contextMenuOpen}
					onClose={() => setContextMenuOpen(false)}
					targetOffset={contextMenuPosition}
					content={contextMenuContent}
				/>

				<ILMap
					openEye={true}
					externalRef={mapRef}
					selectedDevice={selectedDevice}
					onMarkerContextMenu={onMarkerContextMenu}
					onMarkerClick={onMarkerClick}
				/>
			</div>
		</Page>
	)
}
