import './DeviceList.scss'

import React, { useState } from 'react'
import Lang from '../../../../../../Lang'
import { DateTime } from 'luxon'
import { AdminOnly } from '../../../../../../Util'
import { Tooltip2 } from '@blueprintjs/popover2'
import { Collapse } from '@blueprintjs/core'
import Accordion from '../../../../../../Components/Accordion'
import { DeviceMarker } from '../../../../../../Components/ILMap'

export default function DeviceList({
	devices,
	profiles,
	searchingFor,
	selectedProfile,
	selectedDevice,
	selectProfile,
	selectDevice
}) {
	const excludedDevices = devices.filter((dev) => !dev.lightingProfileId)
	let defaultExpanded = devices.length < 20
	return (
		<div className="DeviceList">
			{profiles.map((profile) => {
				const devicesForProfile = devices.filter((dev) => dev.lightingProfileId == profile.id)
				return (
					<DeviceProfileList
						key={`profilelist${profile.id}`}
						profile={profile}
						devices={devicesForProfile}
						defaultExpanded={defaultExpanded}
						searchingFor={searchingFor}
						selectedProfile={selectedProfile}
						selectedDevice={selectedDevice}
						selectProfile={selectProfile}
						selectDevice={selectDevice}
					/>
				)
			})}
			{excludedDevices.length > 0 && (
				<DeviceProfileList
					profile={{ color: 'gray', title: Lang.get('Other Devices') }}
					devices={excludedDevices}
					defaultExpanded={defaultExpanded}
					searchingFor={searchingFor}
					selectedProfile={selectedProfile}
					selectedDevice={selectedDevice}
					selectProfile={selectProfile}
					selectDevice={selectDevice}
				/>
			)}
		</div>
	)
}

function DeviceProfileList({
	selectedProfile,
	selectedDevice,
	profile,
	devices,
	searchingFor,
	selectProfile,
	selectDevice,
	defaultExpanded
}) {
	const isSelected = selectedProfile == profile

	devices.sort((a, b) => a.id - b.id)

	const [isExpanded, setIsExpanded] = useState(defaultExpanded)

	let devicesToShow = null
	if (searchingFor.length > 0) {
		const s = searchingFor
		if (s == '*') {
			devicesToShow = devices
		} else {
			devicesToShow = devices.filter((dev) => {
				return dev.id.toString().startsWith(s)
			})
		}
		if (devicesToShow.length === 0) {
			return null
		}
	} else {
		devicesToShow = devices
	}

	return (
		<Accordion
			isOpen={isExpanded}
			onOpenChange={setIsExpanded}
			title={`${profile.title} (${devices.length})`}
			onHeaderClick={() => {
				if (profile.disabled || !profile.id) return
				selectProfile(profile)
			}}
			selected={profile.id && isSelected}
			disabled={profile.disabled}
			left={
				<div className="lighting-profile-icon-box" style={{ backgroundColor: profile.color }}>
					<i className="fa-fw fa-solid fa-chart-simple" />
				</div>
			}>
			<div className="DeviceListGroup">
				<div className="content">
					{devicesToShow.map((dev) => (
						<DeviceListItem
							key={dev.id}
							device={dev}
							profile={profile}
							onClick={() => selectDevice(dev, { from: 'list' })}
							selected={selectedDevice?.id == dev.id}
						/>
					))}
				</div>
			</div>
		</Accordion>
	)
}

// =============================================================================

class DeviceListItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			gateway: null
		}
	}

	componentDidMount() {
		this.checkGSM()
	}

	checkGSM() {
		const { device } = this.props
		let foundGateway = null
		for (const gtw of device.gateways) {
			if (gtw.serialNr === device.serialNr && DateTime.fromSeconds(gtw.lastDataTime).diffNow('days').toObject().days > -7) {
				//console.log(device.id, gtw.lastDataTime, DateTime.fromSeconds(gtw.lastDataTime).diffNow('days').toObject())
				foundGateway = gtw
				break
			}
		}

		if (foundGateway) {
			if (this.state.gateway !== foundGateway) {
				this.setState({ gateway: foundGateway })
			}
		}
	}

	render() {
		const className = ['DeviceListItem']
		if (this.props.selected) {
			className.push('selected')
		}

		const statusIcons = []

		if (!this.props.device.isConfigured) {
			statusIcons.push({
				icon: 'fa-loader',
				title: Lang.get('Installing configuration...'),
				color: 'orange'
			})
		}

		return (
			<div className={className.join(' ')} onClick={this.props.onClick}>
				<div className="icon">
					{/* <i className={'fa-fw fa-duotone fa-' + deviceIcon} /> */}
					<DeviceMarker
						device={this.props.device}
						settings={{
							view: {
								icons: {
									type: 'device-status'
								}
							},
							showIDLabel: false
						}}
						isSelected={false}
						onMarkerClick={() => {}}
						onMarkerContextMenu={() => {}}
						hideLightingProfileShadow={true}
						disableHoverTooltip={true}
					/>
				</div>
				<div className="content">
					<div className="firstLine">
						<span className="deviceId">{this.props.device.id}</span>
						<div className="statusIcons">
							{statusIcons.map((icon, index) => (
								<Tooltip2 content={icon.title} position="bottom" key={`dlisi-${icon.title}${index}`}>
									<i className={`fa-fw fa-duotone ${icon.icon}`} style={{ color: icon.color }} />
								</Tooltip2>
							))}
						</div>
						<span className="name">{this.props.device.title}</span>
						<AdminOnly>
							<span className="info">{this.props.device.info}</span>
						</AdminOnly>
					</div>
				</div>
			</div>
		)
	}
}
