import './DaliOverrideDialog.scss'

import { useEffect, useState } from 'react'
import { Button, Dialog, DialogBody, DialogFooter, Slider } from '@blueprintjs/core'
import API from '../../../../../../../API'
import { DateTime } from 'luxon'
import { TimePicker } from '@blueprintjs/datetime'
import { dateToSecondsToday, secondsTodayToDate } from '../../../../../../../Util'

export default function DaliOverrideDialog({ deviceId, open, onOpenChange, onSuccess }) {
	const [isBusy, setIsBusy] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const [endTime, setEndTime] = useState(new Date())
	useEffect(() => open && setEndTime(new Date()), [open])

	const [brightness, setBrightness] = useState(50)

	async function onConfirm() {
		setIsBusy(true)
		setErrorMessage(null)

		const duration = Math.max(DateTime.fromJSDate(endTime).toUnixInteger() - DateTime.now().toUnixInteger(), 60)

		const res = await API.call('Device.SetDaliOverride', {
			deviceId,
			brightness,
			duration
		})

		if (res.success) {
			onSuccess({
				endTimestamp: DateTime.now().plus({ seconds: duration }).toUnixInteger(),
				brightness
			})
			onOpenChange(false)
			setEndTime(new Date())
		} else {
			setErrorMessage(res.error)
		}

		setIsBusy(false)
	}

	return (
		<Dialog
			isOpen={open}
			onClose={() => onOpenChange(false)}
			className="dali-override-dialog"
			title={
				<>
					<span>Override DALI</span>
					{errorMessage && <span className="error-message"> - {errorMessage}</span>}
				</>
			}
			icon="wrench-time">
			<DialogBody>
				<div className="brightness-block">
					<span>Brightness</span>
					<Slider value={brightness} onChange={setBrightness} min={0} max={100} stepSize={5} labelStepSize={10} />
				</div>
			</DialogBody>
			<DialogFooter actions={<Button intent="primary" text="Override" loading={isBusy} onClick={onConfirm} />}>
				<div className="duration-block">
					<span>Until</span>
					<div>
						<TimePicker
							value={endTime}
							onChange={(newDate) => {
								// check if is in past
								if (DateTime.fromJSDate(newDate).valueOf() < DateTime.now().valueOf()) {
									newDate = DateTime.now().toJSDate()
								}

								setEndTime(newDate)
							}}
							minTime={DateTime.now().minus({ minutes: 1 }).toJSDate()}
							maxTime={DateTime.now().plus({ hour: 18 }).toJSDate()}
						/>
					</div>
				</div>
			</DialogFooter>
		</Dialog>
	)
}
