import './index.scss'

import { useCallback, useEffect, useRef, useState } from 'react'
import API from '../../API'
import { DateTime } from 'luxon'
import Lang from '../../Lang'
import Placeholder from '../../Components/Placeholder'
import { Button, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import Actions from './Components/Actions'
import { AdminOnly, getMapBoundsForDevices } from '../../Util'
import AccountSelector from '../../Components/AccountSelector'
import Time from '../../Components/Time'
import ProfilesDevicesSidebar from './Components/ProfilesDevicesSidebar'
import { NavLink } from 'react-router-dom'
import ReplaceDialog from './Components/ReplaceDialog'
import ILMap from '../../Components/ILMap'
import { useAppContext } from '../../Components/App'

export default function InspectorPage() {
	const { devices, setDevices, profiles, updateProfileForDevices } = useAppContext()

	const mapRef = useRef(null)

	useEffect(() => {
		document.title = 'Inspector - Idea Lights'

		let meta = document.querySelector('meta[name="viewport"]')
		if (!meta) {
			meta = document.createElement('meta')
			meta.name = 'viewport'
			document.head.appendChild(meta)
		}
		meta.content = 'width=device-width, initial-scale=1'

		return () => {
			let meta = document.querySelector('meta[name="viewport"]')
			if (meta) {
				meta.remove()
			}
		}
	}, [])

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [replaceDialogOpen, setReplaceDialogOpen] = useState(false)

	const [editingDevice, setEditingDevice] = useState({
		id: -1,
		title: '',
		latitude: 0,
		longitude: 0,
		lightingProfileId: -1
	})
	const [saving, setSaving] = useState(0)
	const [unsavedChanges, setUnsavedChanges] = useState(false)
	async function handleSave() {
		if (!activeDevice) return

		const newDeviceData = { ...editingDevice }

		if (newDeviceData.latitude == '') newDeviceData.latitude = null
		if (newDeviceData.longitude == '') newDeviceData.longitude = null

		setSaving(1)
		const r1 = await API.call('Device.Update', {
			id: activeDevice.id,
			...newDeviceData
		})

		if (!r1 || !r1.success) {
			console.error('Failed to update device', r1)
			return
		}

		setDevices((devices) => {
			return devices.map((d) => {
				if (d.id == activeDevice.id) {
					return { ...d, ...newDeviceData, id: activeDevice.id }
				}

				return d
			})
		})

		// set profile to device
		if (activeDevice.lightingProfileId != newDeviceData.lightingProfileId && newDeviceData.lightingProfileId != -1) {
			const r2 = await API.call('LightingProfile.AddDevice', {
				deviceId: activeDevice.id,
				profileId: newDeviceData.lightingProfileId
			})

			if (!r2 || !r2.success) {
				console.error('Failed to add device to profile', r2)
				return
			}

			updateProfileForDevices([activeDevice.id], newDeviceData.lightingProfileId)
		}

		setSaving(2)
		setTimeout(() => setSaving(0), 1000)

		setUnsavedChanges(false)
	}

	const [selectingFromPin, setSelectingFromPin] = useState(false)
	const [activeDevice, setActiveDevice] = useState(null)

	function flyToDevice(device, fromMap = false) {
		const coords = [device.longitude, device.latitude]
		if (!coords.every((c) => c)) return

		mapRef.current?.flyTo({
			center: coords,
			...(!fromMap && { zoom: 18 }),
			essential: true
		})
	}

	const center = useRef(null)

	const onMapMove = useCallback(() => {
		center.current = mapRef.current?.getCenter()
	}, [])

	function startSelectingFromPin() {
		setSelectingFromPin(true)
	}

	function stopSelectingFromPin(cancel) {
		if (!cancel) {
			const { lat, lng } = center.current

			setUnsavedChanges(true)
			setEditingDevice((o) => {
				return { ...o, latitude: lat, longitude: lng }
			})
		} else {
			if (activeDevice) {
				flyToDevice(activeDevice)
			}
		}

		setSelectingFromPin(false)
	}

	function showActiveDeviceOnMap() {
		setActiveDevice((dev) => {
			if (dev) {
				flyToDevice(dev)
			}

			return null
		})
	}

	const previousEditingDeviceRef = useRef(null)

	const selectDevice = useCallback(
		(device, fromMap = false) => {
			setActiveDevice(device)
			setEditingDevice((currentEditingDevice) => {
				previousEditingDeviceRef.current = currentEditingDevice

				const ret = {
					...{
						id: device.id,
						title: device.title ?? '',
						latitude: device.latitude ?? '',
						longitude: device.longitude ?? '',
						lightingProfileId: device.lightingProfileId ?? -1
					}
				}

				return ret
			})

			setUnsavedChanges(false)

			flyToDevice(device, fromMap)

			return true
		},
		[setActiveDevice, setEditingDevice, setUnsavedChanges, flyToDevice]
	)

	function onReady(zoom = true) {
		mapRef.current?.resize()

		if (devices.length > 0 && zoom) mapRef.current?.fitBounds(getMapBoundsForDevices(devices), { padding: 50, duration: 250 })
	}

	const didInit = useRef(false)
	useEffect(() => {
		if (devices !== null && profiles !== null && !didInit.current) {
			onReady()
			didInit.current = true
		}
	}, [devices, profiles])

	const middle = (
		<div className="details" data-inactive={activeDevice == null || selectingFromPin}>
			<div className="close-btn" onClick={() => setActiveDevice(null)}>
				<i className="fa-solid fa-xmark" style={{ fontSize: '2rem' }}></i>
			</div>

			{activeDevice != null && (
				<div className="details__body">
					<div className="device-id">
						Device ID: <strong>{activeDevice.id}</strong>
					</div>

					<FormGroup label={<strong>Title</strong>} labelFor="device-title-input" style={{ margin: 0 }}>
						<InputGroup
							id="device-title-input"
							placeholder="Title"
							value={editingDevice.title}
							onChange={(e) => {
								setUnsavedChanges(true)
								setEditingDevice((o) => ({ ...o, title: e.target.value }))
							}}
						/>
					</FormGroup>

					<div className="location-group">
						<strong>Location</strong>
						<div>
							<Button icon={'map'} onClick={showActiveDeviceOnMap}>
								Show on map
							</Button>

							<Button icon={'pin'} onClick={startSelectingFromPin}>
								Change GPS
							</Button>

							<div className="coordinates">
								<FormGroup labelFor="device-lat-input" style={{ margin: 0 }}>
									<InputGroup
										id="device-lat-input"
										placeholder="Latitude"
										value={editingDevice.latitude}
										onChange={(e) => {
											setUnsavedChanges(true)
											setEditingDevice((o) => ({ ...o, latitude: e.target.value }))
										}}
									/>
								</FormGroup>

								<FormGroup labelFor="device-lng-input" style={{ margin: 0 }}>
									<InputGroup
										id="device-lng-input"
										placeholder="Longitude"
										value={editingDevice.longitude}
										onChange={(e) => {
											setUnsavedChanges(true)
											setEditingDevice((o) => ({ ...o, longitude: e.target.value }))
										}}
									/>
								</FormGroup>
							</div>
						</div>
					</div>

					<FormGroup label={<strong>Lighting profile</strong>} labelFor="device-lighting-profile" style={{ margin: 0 }}>
						<div className="bp5-html-select" style={{ width: '100%' }}>
							<select
								style={{ fontSize: 'large' }}
								onChange={(e) => {
									setUnsavedChanges(true)
									setEditingDevice((o) => ({ ...o, lightingProfileId: e.target.value === -1 ? null : parseInt(e.target.value) }))
								}}
								value={editingDevice.lightingProfileId}>
								<option value={-1}>None</option>
								{profiles.map((p) => (
									<option value={p.id} key={`lp-option${p.id}`}>
										{p.title}
									</option>
								))}
							</select>
							<span className="bp5-icon bp5-icon-double-caret-vertical" />
						</div>
					</FormGroup>

					<div className="info-block">
						<strong>{Lang.get('Last Heard')}: </strong>
						<span>
							<Time timestamp={activeDevice.lastDataTime} />
						</span>

						{!activeDevice.isConfigured && (
							<Tooltip2 content={Lang.get('Installing configuration...')} position="bottom">
								<i className={`fa-fw fa-duotone fa-loader`} style={{ color: 'orange' }} />
							</Tooltip2>
						)}

						{(() => {
							let lastSeenError = null
							if (!activeDevice.lastDataTime) {
								lastSeenError = Lang.get('No signal')
							} else {
								const lastSeen = DateTime.fromSeconds(activeDevice.lastDataTime)
								const diff = DateTime.now().diff(lastSeen, ['months', 'weeks', 'days', 'hours', 'minutes']).toObject()
								if (diff.months > 0) {
									lastSeenError = Lang.get('Last signal') + ' ' + Lang.get('%s months ago', diff.months)
								} else if (diff.weeks > 0) {
									lastSeenError = Lang.get('Last signal') + ' ' + Lang.get('%s weeks ago', diff.weeks)
								} else if (diff.days > 0) {
									lastSeenError = Lang.get('Last signal') + ' ' + Lang.get('%s days ago', diff.days)
								}
							}

							if (lastSeenError) {
								return (
									<Tooltip2 content={lastSeenError} position="bottom">
										<i className={`fa-fw fa-duotone fa-exclamation-triangle`} style={{ color: 'red' }} />
									</Tooltip2>
								)
							}
						})()}
					</div>

					<AdminOnly>
						<button className="bp5-button" onClick={() => setReplaceDialogOpen(true)}>
							Replace / swap
						</button>
					</AdminOnly>
				</div>
			)}

			{(unsavedChanges || saving == 2) && (
				<Button
					intent="primary"
					className="save-btn"
					onClick={handleSave}
					disabled={saving == 1 || saving == 2}
					icon={saving == 1 ? <Spinner className="spinner-icon white" /> : saving == 2 ? 'tick' : 'blank'}>
					Save
				</Button>
			)}
		</div>
	)

	const bottom = (
		<div className={`enter ${activeDevice && 'panel'}`}>
			{(() => {
				if (selectingFromPin) {
					return (
						<div className="selecting-pin-btn">
							<Button intent="danger" icon={'stop'} onClick={() => stopSelectingFromPin(true)}>
								Cancel
							</Button>
							<Button intent="primary" icon={'pin'} onClick={() => stopSelectingFromPin(false)}>
								Select
							</Button>
						</div>
					)
				}

				if (!activeDevice) {
					return (
						<button className="bp5-button inspector-button" onClick={() => setSidebarOpen(true)}>
							<i className="fa-solid fa-search"></i>
						</button>
					)
				}

				return <Actions device={activeDevice} />
			})()}
		</div>
	)

	return (
		<>
			<Placeholder overlay overlayVisible={devices === null} loading label={Lang.get('Loading devices') + ' ...'} />
			<main className="technician-page">
				<div className="overlay">
					{!activeDevice && (
						<div className="top-left">
							<NavLink to={'/'} style={{ pointerEvents: 'all' }}>
								<button className="bp5-button inspector-button home-btn" onClick={() => setSidebarOpen(true)}>
									<i className="fa-solid fa-house"></i>
								</button>
							</NavLink>
							<div className="account">
								<AccountSelector />
							</div>
						</div>
					)}

					{middle}

					{bottom}
				</div>

				<ILMap
					externalRef={mapRef}
					profiles={profiles}
					devices={devices}
					selectedDevice={activeDevice}
					onMarkerClick={(device) => selectDevice(device, true)}
					onMove={onMapMove}
					onClick={() => setSidebarOpen(false)}></ILMap>

				{selectingFromPin && <div className="center-pin"></div>}
			</main>

			<ProfilesDevicesSidebar
				open={sidebarOpen}
				onOpenChange={setSidebarOpen}
				devices={devices}
				profiles={profiles}
				onDeviceSelect={async (dev) => {
					setSidebarOpen(false)
					selectDevice(dev)
				}}
			/>

			<ReplaceDialog
				open={replaceDialogOpen}
				setOpen={setReplaceDialogOpen}
				selectedDevice={activeDevice}
				onSuccess={() => setActiveDevice(null)}
				devices={devices}
			/>
		</>
	)
}
