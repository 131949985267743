import './ILMap.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Lang from '../Lang'
import mapboxgl from 'mapbox-gl'
import { Classes, Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Button, Menu, MenuItem, MenuDivider, Slider, Spinner, Collapse } from '@blueprintjs/core'
import { mapOverlays } from '../Assets/overlays'
import { capitalizeFirstLetter, getAgoText, getMapBoundsForDevices, isDeviceGateway, valueToHexColor } from '../Util'
import { GeolocateControl, Layer, Map, MapboxMap, Marker, Source } from 'react-map-gl'
import { LngLatBounds } from 'mapbox-gl'
import { DateTime } from 'luxon'
import { EVENT_LABELS } from '../Pages/Map/Components/Tabs/Events'
import { DateInput3 } from '@blueprintjs/datetime2'
import API from '../API'
import useSettings from '../Hooks/useSettings'
import { useAppContext } from './App'
import Time from './Time'
import ProfileIcon from './CustomIcons/ProfileIcon'
import IconTextBox from './IconTextBox'
import StatusRingIcon from './CustomIcons/StatusRingIcon'
import GlowCircleIcon from './CustomIcons/GlowCircleIcon'

import arrowLeft from '../Assets/arrow-left.svg'

const COLORS = {
	RED: '#FF0000',
	GREEN: '#00FF00',
	BLUE: '#0000FF',
	YELLOW: '#FFFF00',
	ORANGE: '#FFA500',
	CYAN: '#00FFFF',
	MAGENTA: '#FF00FF',
	TRANSPARENT: 'transparent',
	WHITE: '#FFFFFF',
	BLACK: '#000000'
}

export default function ILMap({
	externalRef,
	selectedDevice,
	onMarkerContextMenu,
	onMarkerClick,
	children,
	openEye = false,
	...rest
}) {
	const { devices, profiles, account } = useAppContext()

	const { setSetting, settings } = useSettings({
		view: {
			icons: {
				type: 'device-status'
			},
			heatMap: {
				enabled: false,
				hour: DateTime.now().hour,
				date: DateTime.now().toFormat('yyyy-LL-dd')
			}
		},
		map: {
			type: 'map'
		},
		showIDLabel: false
	})

	const [viewSettingsOpen, setViewSettingsOpen] = useState(false)

	const [isHeatMapLoading, setIsHeatMapLoading] = useState(false)
	const [heatMapData, setHeatMapData] = useState(null)

	useEffect(() => {
		if (isHeatMapLoading || !settings.view.heatMap.enabled || !settings.view.heatMap.date) return
		;(async () => {
			setIsHeatMapLoading(true)

			const data = await API.call('Statistics.TrafficData', {
				deviceIds: devices.map((d) => d.id),
				date: settings.view.heatMap.date
			})

			setHeatMapData(data)

			setIsHeatMapLoading(false)
		})()
	}, [settings.view.heatMap.enabled, settings.view.heatMap.date])

	const internalRef = useRef(null)

	const mapRef = externalRef ?? internalRef

	const mapStyle = useMemo(() => {
		switch (settings.map.type) {
			case 'map':
				return 'mapbox://styles/mapbox/streets-v12'
			case 'satellite':
				return 'mapbox://styles/mapbox/satellite-v9'
			default:
				console.error('Unsupported map type:', settings.map.type)
				return 'mapbox://styles/mapbox/streets-v12'
		}
	}, [settings.map.type])

	useEffect(() => {
		if (!mapRef.current) return

		mapRef.current.resize()

		if (selectedDevice) {
			const curZoom = mapRef.current.getZoom()
			const targetZoom = 15

			const coords = [selectedDevice.longitude, selectedDevice.latitude]

			if (!coords.every((c) => c)) return

			const flyToOptions = {
				center: coords,
				...(curZoom < targetZoom && { zoom: targetZoom }),
				essential: true
			}
			mapRef.current.flyTo(flyToOptions)
		}
	}, [selectedDevice])

	const heatmapLayer = useMemo(() => {
		if (!settings.view.heatMap.enabled || heatMapData === null) return null

		const features = (devices ?? [])
			.filter((d) => d.latitude && d.longitude)
			.map((device) => {
				const trafficPercentage = heatMapData[settings.view.heatMap.hour]?.[device.id]

				return {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [device.longitude, device.latitude]
					},
					properties: {
						int: trafficPercentage ? trafficPercentage / 100 : 0
					}
				}
			})

		return (
			<Source
				id="heatmap-layer"
				type="geojson"
				data={{
					type: 'FeatureCollection',
					features
				}}>
				<Layer
					id="heatmap-heat"
					type="heatmap"
					source="heatmap-layer"
					paint={{
						'heatmap-weight': {
							type: 'identity',
							property: 'int'
						},
						'heatmap-radius': 50,
						'heatmap-intensity': 2
					}}></Layer>
			</Source>
		)
	}, [settings.view.heatMap.enabled, settings.view.heatMap.date, settings.view.heatMap.hour, heatMapData])

	const mapOverlay = useMemo(() => {
		const overlay = mapOverlays.find((o) => o.accountId == account?.id)
		if (!overlay) return null

		return (
			<Source id={`${overlay.id}-layer`} type="image" url={overlay.image} coordinates={overlay.coordinates}>
				<Layer id="overlays-layer" type="raster" source={`${overlay.id}-layer`} paint={{ 'raster-opacity': 0.8 }}></Layer>
			</Source>
		)
	}, [])

	const onTrackUserLocationStart = useCallback(
		(coords) => {
			if (!mapRef.current || !coords) return

			mapRef.current?.fitBounds(getMapBoundsForDevices([...devices, { latitude: coords[0], longitude: coords[1] }]), {
				padding: 50,
				duration: 250
			})
		},
		[devices]
	)

	const geoControlRef = useRef()
	const userCoordsRef = useRef(null)
	const [waitingForCoordinates, setWaitingForCoordinates] = useState(false)

	useEffect(() => {
		if (!waitingForCoordinates) {
			if (onTrackUserLocationStart && userCoordsRef.current) {
				onTrackUserLocationStart(userCoordsRef.current)
			}
		}
	}, [waitingForCoordinates])

	function triggerLocate() {
		geoControlRef.current?.trigger()

		if (!userCoordsRef.current) {
			setWaitingForCoordinates(true)
		}

		if (onTrackUserLocationStart && userCoordsRef.current) {
			onTrackUserLocationStart(userCoordsRef.current)
		}
	}

	function onLoad(e) {
		if (!e.target) return

		const img = new Image(32, 32)
		img.src = arrowLeft

		img.onload = () => {
			if (e.target.hasImage('arrow-left')) return
			e.target.addImage('arrow-left', img, { sdf: true })
		}
	}

	const didInit = useRef(false)
	const onStyleData = useCallback(
		(e) => {
			if (didInit.current || !e.target || !devices) return e

			didInit.current = true

			const bounds = getMapBoundsForDevices(devices)
			if (bounds) {
				e.target.fitBounds(bounds, {
					padding: 50,
					duration: 250
				})
			}

			return e
		},
		[devices]
	)

	return (
		<div className="Map">
			<div className="tools">
				<div className="eye-tool">
					{settings.view.heatMap.enabled && (
						<div className="heatmap-settings-wrapper">
							<div className="heatmap-settings">
								<div className="heatmap-input-item" onClick={(e) => e.stopPropagation()}>
									<DateInput3
										value={settings.view.heatMap.date}
										onChange={(value) => setSetting('view.heatMap.date', value)}
										maxDate={new Date()}
										disabled={!settings.view.heatMap.enabled || isHeatMapLoading}
										locale={null}
										popoverProps={{ usePortal: false }}
										dayPickerProps={{ weekStartsOn: 1 }}
									/>
								</div>
								<div className="heatmap-input-item" onClick={(e) => e.stopPropagation()}>
									<Slider
										value={settings.view.heatMap.hour}
										onChange={(value) => setSetting('view.heatMap.hour', value)}
										min={0}
										max={23}
										stepSize={1}
										labelValues={[]}
										disabled={!settings.view.heatMap.enabled || isHeatMapLoading}
									/>
								</div>
							</div>
						</div>
					)}

					<Popover2
						content={
							<Menu>
								<MenuDivider title={Lang.get('Show')} />
								<MenuItem
									icon={settings.view.icons.type == 'device-status' ? 'tick' : 'blank'}
									text={Lang.get('Device Status')}
									onClick={() => setSetting('view.icons.type', 'device-status')}
									shouldDismissPopover={false}
								/>
								<MenuItem
									icon={settings.view.icons.type == 'lighting-profile' ? 'tick' : 'blank'}
									text={Lang.get('Lighting Profile')}
									onClick={() => setSetting('view.icons.type', 'lighting-profile')}
									shouldDismissPopover={false}
								/>
								<MenuItem
									icon={settings.view.icons.type == 'report-health' ? 'tick' : 'blank'}
									text={Lang.get('Report Health')}
									onClick={() => setSetting('view.icons.type', 'report-health')}
									shouldDismissPopover={false}
								/>

								<MenuDivider />

								<MenuItem
									icon={
										!isHeatMapLoading ? settings.view.heatMap.enabled ? 'tick' : 'blank' : <Spinner className="spinner-icon" />
									}
									text={Lang.get('Enable Traffic Heatmap')}
									onClick={() => setSetting('view.heatMap.enabled', !settings.view.heatMap.enabled)}
									className={Classes.POPOVER2_DISMISS}
								/>
							</Menu>
						}
						placement="left">
						<Button icon="eye-open" style={{ marginLeft: 'auto' }} />
					</Popover2>
				</div>

				<Popover2
					targetProps={{ style: { marginLeft: 'auto' } }}
					content={
						<Menu>
							<MenuItem
								icon={settings.map.type == 'map' ? 'tick' : 'blank'}
								text={Lang.get('Map')}
								onClick={() => setSetting('map.type', 'map')}
								shouldDismissPopover={false}
							/>
							<MenuItem
								icon={settings.map.type == 'satellite' ? 'tick' : 'blank'}
								text={Lang.get('Satellite')}
								onClick={() => setSetting('map.type', 'satellite')}
								shouldDismissPopover={false}
							/>
						</Menu>
					}
					placement="left">
					<Button icon="map" style={{ marginLeft: 'auto' }} />
				</Popover2>

				<Button
					icon="numerical"
					className={!settings.showIDLabel ? 'fake-strikethrough' : ''}
					style={{ marginLeft: 'auto' }}
					onClick={() => setSetting('showIDLabel', !settings.showIDLabel)}
				/>

				<Button
					icon="locate"
					id="locate-btn"
					loading={waitingForCoordinates}
					onClick={triggerLocate}
					style={{ marginLeft: 'auto' }}
				/>
			</div>
			<Map
				mapboxAccessToken="pk.eyJ1IjoiZW1vcmFpdmlzIiwiYSI6ImNsOWNyOWF2dTF3bGMzcmxtOHUwd2d0YnYifQ.SyOOwd_WDYDpAdhPR5YK_g"
				mapStyle={mapStyle}
				ref={mapRef}
				initialViewState={{
					latitude: 56.9677,
					longitude: 24.1056,
					zoom: 6
				}}
				onLoad={onLoad}
				onStyleData={(e) => {
					onStyleData(e)
					return e
				}}
				{...rest}>
				<GeolocateControl
					positionOptions={{ enableHighAccuracy: true }}
					fitBoundsOptions={{ zoom: 10 }}
					trackUserLocation={true}
					showUserHeading={true}
					ref={geoControlRef}
					onGeolocate={(e) => {
						const coords = [e.coords.latitude, e.coords.longitude]
						userCoordsRef.current = coords
						setWaitingForCoordinates(false)
					}}
				/>

				{children}

				{devices?.map((device, index) => {
					const isSelected = selectedDevice?.id == device.id

					const hasActiveEvents = device.events?.some((e) => e.isActive)
					const hasError = hasActiveEvents && device.events?.some((e) => e.level === 'ERROR')

					const zIndex = getZIndexForDevice(device, hasActiveEvents, hasError)

					return (
						<Marker latitude={device.latitude} longitude={device.longitude} anchor="center" style={{ zIndex }} key={index}>
							<DeviceMarker
								device={device}
								profiles={profiles}
								settings={settings}
								isSelected={isSelected}
								onMarkerContextMenu={onMarkerContextMenu}
								onMarkerClick={onMarkerClick}
								hideLightingProfileShadow={settings.view.heatMap.enabled}
								trafficIntensityForThisHour={
									settings.view.heatMap.enabled ? heatMapData?.[settings.view.heatMap.hour]?.[device.id] ?? null : null
								}
							/>
						</Marker>
					)
				})}

				<FeaturesLayer selectedDevice={selectedDevice} devices={devices} />

				{heatmapLayer}

				{mapOverlay}
			</Map>
		</div>
	)
}

export const ONLINE_STATUS_LABELS = {
	online: Lang.get('Online'),
	standby: Lang.get('Standby'),
	offline: Lang.get('Offline')
}

export const GLOW_STATUS_LABELS = {
	1: Lang.get('Glowing'),
	'-1': Lang.get('Not Glowing'),
	0: Lang.get('Unknown')
}

export function getRingColorForDevice(device) {
	if (device.status.onlineStatus === 'online') return COLORS.GREEN
	if (device.status.onlineStatus === 'standby') return COLORS.ORANGE

	return COLORS.RED
}

export function getFillColorForDeviceGlowStatus(device) {
	if (device.status.currentGlow === '1') {
		return COLORS.GREEN
	} else if (device.status.currentGlow === '-1') {
		return COLORS.BLACK
	} else if (device.status.currentGlow === '0') {
		return COLORS.WHITE
	}

	return 'gray'
}

function getZIndexForDevice(device, hasActiveEvents, hasError) {
	switch (device.status.onlineStatus) {
		case 'online': {
			return hasActiveEvents ? (hasError ? 3 : 2) : 1
		}
		case 'standby': {
			return hasActiveEvents ? (hasError ? 6 : 5) : 4
		}
		case 'offline': {
			return hasActiveEvents ? (hasError ? 9 : 8) : 7
		}
	}

	return 0
}

export function DeviceMarker({
	device,
	profiles,
	settings,
	isSelected,
	onMarkerContextMenu,
	onMarkerClick,
	hideLightingProfileShadow = false,
	trafficIntensityForThisHour = null,
	disableHoverTooltip = false
}) {
	const getCoreColorForDevice = useCallback(
		(device) => {
			if (device.status.supposedGlow === 'disabled' || settings.view.icons.type != 'device-status') return COLORS.TRANSPARENT

			if (device.status.supposedGlow === '1') return COLORS.GREEN
			if (device.status.supposedGlow === '-1') return COLORS.BLACK

			return COLORS.WHITE
		},
		[settings.view.icons.type]
	)

	const getFillColorForDevice = useCallback(
		(device) => {
			let ret = 'gray'

			switch (settings.view.icons.type) {
				case 'device-status': {
					return getFillColorForDeviceGlowStatus(device)
				}

				case 'lighting-profile': {
					if (profiles) {
						const profile = profiles.find((p) => p.id == device.lightingProfileId)
						if (profile) {
							ret = profile.color
						}
					}

					break
				}
				case 'report-health': {
					const reportCount = device.reportHealth
					const healthPercentage = reportCount / 30
					ret = valueToHexColor(healthPercentage)

					break
				}

				default: {
					console.error('Unsupported marker fill color type:', settings.view.icons.type)
					break
				}
			}

			return ret
		},
		[profiles, settings.view.icons.type]
	)

	const activeEvents = device.events?.filter((e) => e.isActive) ?? []
	const hasErrors = activeEvents?.some((e) => e.level === 'ERROR')

	const fillColor = getFillColorForDevice(device)
	const ringColor = getRingColorForDevice(device)
	const coreColor = getCoreColorForDevice(device)

	const deviceProfile = profiles?.find((p) => p.id === device.lightingProfileId)
	const lightingProfileColor = hideLightingProfileShadow ? 'transparent' : deviceProfile?.color ?? 'black'

	return (
		<div
			style={{
				'--fillColor': fillColor,
				'--ringColor': ringColor,
				'--coreColor': coreColor,
				'--lightingProfileColor': lightingProfileColor
			}}
			className={`device-marker ${isSelected ? 'selected' : ''} ${device.mode === '2' ? 'segment' : ''}`}
			onContextMenu={(e) => onMarkerContextMenu(e, device)}
			onClick={() => onMarkerClick(device)}>
			<div className="core"></div>

			{settings.showIDLabel && <div className="label">{device.id}</div>}

			{isDeviceGateway(device) && <i className="gateway-icon fa-fw fa-duotone fa-wifi"></i>}

			{activeEvents.length > 0 && (
				<i className={`event-icon fa-fw fa-solid fa-exclamation-triangle ${hasErrors ? 'error' : ''}`}></i>
			)}

			{!disableHoverTooltip && (
				<div className="hover-tooltip-container container-shadow">
					<HoverTooltipContent
						device={device}
						lightingProfileColor={lightingProfileColor}
						lightingProfileName={deviceProfile?.title}
						trafficIntensityForThisHour={trafficIntensityForThisHour}
					/>
				</div>
			)}
		</div>
	)
}

function HoverTooltipContent({ device, lightingProfileColor, lightingProfileName, trafficIntensityForThisHour }) {
	const lastDataTimeDt = device.lastDataTime ? DateTime.fromSeconds(device.lastDataTime) : null

	const activeEvents = device.events?.filter((e) => e.isActive)

	return (
		<>
			<div className="id">{device.id}</div>

			{device.title && <div className="title">{device.title}</div>}

			{lastDataTimeDt &&
				(() => {
					let lastSeenText = getAgoText(lastDataTimeDt)

					return (
						<>
							<div className="last-data-time">
								<Time timestamp={lastDataTimeDt?.toUnixInteger()} />
							</div>
							<div className="last-data-time">{lastSeenText}</div>
						</>
					)
				})()}

			{trafficIntensityForThisHour !== null && (
				<span>
					{Lang.get('Traffic')}: {trafficIntensityForThisHour.toFixed(2)}%
				</span>
			)}

			{lightingProfileName && (
				<IconTextBox iconElement={<ProfileIcon profileColor={lightingProfileColor} size={1} />}>
					{lightingProfileName}
				</IconTextBox>
			)}

			{device.status && (
				<IconTextBox iconElement={<StatusRingIcon color={getRingColorForDevice(device)} size={1.1} />}>
					{ONLINE_STATUS_LABELS[device.status.onlineStatus]}
				</IconTextBox>
			)}

			{device.status && (
				// <div className="icon-text-box">
				// 	<div className="glow-circle" style={{ backgroundColor: getFillColorForDeviceGlowStatus(device) }}></div>

				// 	<span>{GLOW_STATUS_LABELS[device.status.currentGlow]}</span>
				// </div>

				<IconTextBox iconElement={<GlowCircleIcon color={getFillColorForDeviceGlowStatus(device)} size={1} />}>
					{GLOW_STATUS_LABELS[device.status.currentGlow]}
				</IconTextBox>
			)}

			{activeEvents?.length > 0 && (
				<div className="active-events">
					{activeEvents.map((event, i) => (
						<IconTextBox
							key={`htaeli${device.id}${i}`}
							iconElement={
								<div
									className={`colored-icon event-flag ${
										event.level === 'ERROR' ? 'error' : event.level === 'WARNING' ? 'warning' : ''
									}`}>
									<i className="fa-fw fa-solid fa-exclamation-triangle" />
								</div>
							}>
							{EVENT_LABELS[event.type]}
						</IconTextBox>
					))}
				</div>
			)}
		</>
	)
}

function createFeature(device, neighbour, color = '#ff6600') {
	if (!device || !neighbour) return null

	return {
		type: 'Feature',
		properties: {
			color
		},
		geometry: {
			type: 'LineString',
			coordinates: [
				[device.longitude, device.latitude],
				[neighbour.longitude, neighbour.latitude]
			]
		}
	}
}

function FeaturesLayer({ selectedDevice, devices }) {
	const layer = useMemo(() => {
		if (!selectedDevice) return null

		const features = selectedDevice.neighbours
			.map((n) => {
				const neighbour = devices.find((d) => d.id == n.id)

				if (!neighbour || !neighbour.latitude || !neighbour.longitude) return null

				return createFeature(selectedDevice, neighbour)
			})
			.filter((n) => n)

		const segmentFeatures = []

		const parentSegmentId = selectedDevice.parentSegmentId
		if (parentSegmentId) {
			const parentSegment = devices.find((d) => d.id === parentSegmentId)

			if (parentSegment) {
				segmentFeatures.push(createFeature(selectedDevice, parentSegment, '#000000'))
			}
		}

		const children = devices.filter((d) => d.parentSegmentId === selectedDevice.id)
		if (children.length > 0) {
			children.forEach((child) => {
				segmentFeatures.push(createFeature(selectedDevice, child, '#000000'))
			})
		}

		return (
			<>
				<Source
					id="neighbour-lines"
					type="geojson"
					data={{
						type: 'FeatureCollection',
						features
					}}>
					<Layer
						id="neighbour-lines-layer"
						type="line"
						source="neighbour-lines"
						paint={{ 'line-color': ['get', 'color'], 'line-width': 4, 'line-opacity': 0.6 }}
					/>

					<Layer
						id="neighbour-lines-direction-layer"
						type="symbol"
						source="neighbour-lines"
						layout={{
							'symbol-placement': 'line-center',
							'symbol-spacing': 1000,
							'symbol-sort-key': 1,
							'icon-image': 'arrow-left',
							'icon-size': 0.75,
							'icon-rotation-alignment': 'map',
							'icon-allow-overlap': true,
							'symbol-z-order': 'source'
						}}
						paint={{
							'icon-color': ['get', 'color']
						}}
					/>
				</Source>
				<Source
					id="segment-lines"
					type="geojson"
					data={{
						type: 'FeatureCollection',
						features: segmentFeatures
					}}>
					<Layer
						id="segment-lines-layer"
						type="line"
						source="segment-lines"
						paint={{ 'line-color': ['get', 'color'], 'line-width': 4, 'line-opacity': 0.6 }}
					/>
				</Source>
			</>
		)
	}, [selectedDevice, devices])

	return <>{layer}</>
}
