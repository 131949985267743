import './index.scss'

import { useState } from 'react'
import Placeholder from '../../../../../Components/Placeholder'
import DeviceList from './Components/DeviceList'
import { Button, Menu, MenuItem } from '@blueprintjs/core'
import Lang from '../../../../../Lang'
import { Popover2 } from '@blueprintjs/popover2'
import { isAdmin } from '../../../../../Util'

export default function DevicesTab({
	wantsNewProfile,
	wantsNewDevice,
	devices,
	profiles,
	selectedProfile,
	selectedDevice,
	selectProfile,
	selectDevice
}) {
	const [addPopoverOpen, setAddPopoverOpen] = useState(false)
	const [searchingFor, setSearchingFor] = useState('')

	function handlePopoverOption(fn) {
		setAddPopoverOpen(false)
		fn()
	}

	return (
		<div className="devices-tab">
			<header>
				<SearchInput value={searchingFor} onChange={(e) => setSearchingFor(e.target.value)} />

				{!isAdmin() ? (
					<Button
						// text={Lang.get('Create New Profile')}
						icon="add"
						variant="minimal"
						intent="primary"
						onClick={() => wantsNewProfile({ version: 2 })}
					/>
				) : (
					<Popover2
						isOpen={addPopoverOpen}
						onInteraction={(state) => setAddPopoverOpen(state)}
						content={
							<Menu>
								<MenuItem
									text={Lang.get('Add profile')}
									icon="grouped-bar-chart"
									onClick={() => handlePopoverOption(() => wantsNewProfile({ version: 2 }))}
									shouldDismissPopover={true}
								/>
								<MenuItem
									text={Lang.get('Add Devices')}
									icon="sensor"
									onClick={() => handlePopoverOption(() => wantsNewDevice())}
									shouldDismissPopover={true}
								/>
							</Menu>
						}>
						<Button icon="add" variant="minimal" intent="primary" />
					</Popover2>
				)}
			</header>
			<div className="devices-tab-content">
				{devices && profiles ? (
					<DeviceList
						devices={devices}
						profiles={profiles}
						searchingFor={searchingFor}
						selectedProfile={selectedProfile}
						selectedDevice={selectedDevice}
						selectProfile={selectProfile}
						selectDevice={selectDevice}
					/>
				) : (
					<Placeholder loading />
				)}
			</div>
		</div>
	)
}

export function SearchInput(props) {
	return (
		<div className="SearchInput">
			<i className="fa-fw fa-duotone fa-search" />
			<input type="text" placeholder={Lang.get('Search...')} value={props.value} onChange={props.onChange} />
		</div>
	)
}
