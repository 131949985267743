import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import Lang from '../../../../../../Lang'

export default function FilterButton({ list, value, onChange, children, icon = 'filter', extraPropsPerKey }) {
	function toggleEventType(eventType) {
		if (value.includes(eventType)) {
			onChange(value.filter((type) => type !== eventType))
		} else {
			onChange([...value, eventType])
		}
	}

	const hasAllSelected = Object.keys(list).every((key) => value.includes(key))

	const filterMenu = (
		<Menu>
			<MenuItem
				text={Lang.get('All')}
				icon={hasAllSelected ? 'tick' : 'blank'}
				onClick={() => onChange(hasAllSelected ? [] : Object.keys(list))}
			/>
			<MenuDivider />
			{Object.keys(list).map((key) => (
				<MenuItem
					key={`etfmi${key}`}
					text={list[key]}
					icon={value.includes(key) ? 'tick' : 'blank'}
					onClick={() => toggleEventType(key)}
					{...(extraPropsPerKey ? extraPropsPerKey[key] : {})}
				/>
			))}
		</Menu>
	)

	return (
		<Popover2 content={filterMenu} position="bottom-right">
			<Button text={children} icon={icon} rightIcon="caret-down" />
		</Popover2>
	)
}
