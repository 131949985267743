/*
 * Copyright 2024 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import type { SVGIconProps } from "../../svgIconProps";
import { IconSize } from "../../iconTypes";
import { SVGIconContainer } from "../../svgIconContainer";

export const SelectionBoxEdit: React.FC<SVGIconProps> = React.forwardRef<any, SVGIconProps>((props, ref) => {
    const isLarge = props.size! >= IconSize.LARGE;
    const pixelGridSize = isLarge ? IconSize.LARGE : IconSize.STANDARD;
    const translation = `${-1 * pixelGridSize / 0.05 / 2}`;
    const style = { transformOrigin: "center" };
    return (
        <SVGIconContainer iconName="selection-box-edit" ref={ref}  {...props}>
            <path
                d={isLarge ? "M100 400H40H20C8.9543 400 0 391.0457 0 380V360V300H40L40 360H100V400zM360 100V40H300V0H360H380C391.046 0 400 8.954 400 20V40V100H360zM0 40V100H40L40 40H100V0H40H20C8.9543 0 0 8.954 0 20V40zM160 400H240V360H160V400zM360 240V160H400V240H360zM0 160V240H40L40 160H0zM240 40H160V0H240V40zM390.96 390.961C378.91 403.013 359.368 403.013 347.3160000000001 390.961L325.494 369.139L369.1380000000001 325.4948L390.96 347.3168C403.014 359.3688 403.014 378.909 390.96 390.961zM310.942 354.5908L354.588 310.9468L194.5586 150.918L150.9146 194.562L310.942 354.5908zM136.37 180.014L180.0142 136.37L100 100L136.37 180.014z" : "M200 280L120 280V320H200V280zM0 200V120H40V200H0zM120 0H200V40H120V0zM280 200H320V120H280V200zM40 320H20C8.9543 320 0 311.0457 0 300V280V240H40V280L80 280V320H40zM0 20V40V80H40L40 40H80V0H40H20C8.9543 0 0 8.954 0 20zM280 0H300C311.046 0 320 8.954 320 20V40V80H280V40H240V0H280zM174.3816 135.5148L135.5394 174.3284L240.9 279.6136L279.584 240.6422L174.3816 135.5148zM160.7006 121.844L100 100L122.0156 160.186L160.7006 121.844zM273.136 311.9857600000001C278.17 316.85716 285.086 320 292.636 320C307.89 320 320.156 307.5858 319.998 292.6574C319.998 284.9574 317.01 278.2004 311.978 273.1718L289.332 250.5432L250.492 289.3572L273.136 311.9857600000001z"}
                fillRule="evenodd"
                transform={`scale(0.05, -0.05) translate(${translation}, ${translation})`}
                style={style}
            />
        </SVGIconContainer>
   );
});
SelectionBoxEdit.defaultProps = {
    size: IconSize.STANDARD,
};
SelectionBoxEdit.displayName = `Blueprint5.Icon.SelectionBoxEdit`;
export default SelectionBoxEdit;
