import { Button } from '@blueprintjs/core'
import ConfirmPopover from '../../../../../../../Components/ConfirmPopover'
import { useState } from 'react'
import { AppToaster, useAppContext } from '../../../../../../../Components/App'
import Lang from '../../../../../../../Lang'

export default function DeviceResetButton({ device, selectDevice }) {
	const { setDevices } = useAppContext()

	const [isResetting, setIsResetting] = useState(false)
	async function wantsReset() {
		setIsResetting(true)

		const res = await API.call('Admin.Device.Reset', { deviceId: device.id })
		if (!res?.success) {
			console.error('Failed to reset device', res)
		} else {
			;(await AppToaster).show({ message: Lang.get('Device %d reset', device.id), intent: 'success' })
			selectDevice(null)
			setDevices((devices) => devices.filter((d) => d.id != device.id))
		}
	}

	return (
		<ConfirmPopover
			message={Lang.get('Are you sure?')}
			confirmTitle={Lang.get('Confirm')}
			onConfirm={wantsReset}
			confirmIntent="danger"
			style={{ width: '100%' }}>
			<Button icon={'trash'} fill loading={isResetting} intent="danger">
				{Lang.get('Delete')}
			</Button>
		</ConfirmPopover>
	)
}
